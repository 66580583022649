import React, { useEffect, useState } from 'react'
import "../css/Contact.css"
import Footer from './Footer'
import Form_input from './Form_input'
import Navbar from './Navbar'
import AOS from "aos";
import "aos/dist/aos.css";

import { BsPhoneFlip } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { FaTelegram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";

export default function Contact() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [contactLan, setContactLan] = useState(localStorage.getItem("lan"))
  return (
    <div>
      {contactLan == 0 ? <div className='Contact_div'>
<Navbar/>
<div className="contact">
    <h1>Бизнинг контактларимиз</h1>
    <div className="contact_div_colum">
        <div className="contact_div_colum_1">
        <div data-aos="flip-left"
data-aos-easing="ease-out-cubic"
data-aos-duration="2000">
        <BsPhoneFlip  className='contact_icon'/>
</div>
            <h2>Телефонлар</h2>
            <div className="contact_phone">
            <p>Тел:</p> <span><a href="tel:+998 90 570 72 27">+998 90 570 72 27</a></span>
            </div>
            <div className="contact_phone">
            <p>Тел:</p> <span><a href="tel:+998 99 805 35 67">+998 99 805 35 67</a></span>
            </div>
            <div className="contact_phone">
            <p>Тел:</p> <span><a href="tel:+998 97 813 77 00">+998 97 813 77 00</a></span>
            </div>
        </div>
        <div className="contact_div_colum_1">
        <div data-aos="flip-left"
data-aos-easing="ease-out-cubic"
data-aos-duration="2000">
        <IoLocationOutline className='contact_icon contact_icon2'/>
</div>
        <div className='h33333333333333333'>
              <h2>Манзил</h2>
          <p className='h2_contact_p'>Манзил: 87RW+8Q7, Тошкент Шаҳри,</p>
          <p className='h2_contact_p'>Ўзбекистон, Боғишамол Кўчаси,</p>
          <p className='h2_contact_p'>LWN coworking</p>
        </div>
          
        </div>
    </div>
</div>

<div className="internet_contact">
  <h1>Бизнинг ижтимоий тармоқларимиз</h1>
  <div className="internet_div">
    <div className="internet_div_1">
      <div className="internet_icon_and_span">
      <FaTelegram className='teleg_contact_icon'/>
        <div className="div_span_contact_teleg">
          <span><a href="https://t.me/sunnur_energy">Телеграм гуруҳи</a></span>
        </div>
      </div>
      <div className="internet_icon_and_span">
      <FaTelegram className='teleg_contact_icon'/>
        <div className="div_span_contact_teleg">
          <span><a href="https://t.me/sunnur_energy">Телеграм канали</a></span>
        </div>
      </div>
      <div className="internet_icon_and_span">
      <FaTelegram className='teleg_contact_icon'/>
        <div className="div_span_contact_teleg">
          <span><a href="https://t.me/Oybek_6979">Телеграм супорт</a></span>
        </div>
      </div>
    </div>
    <div className="internet_div_2">
      <div className="internet_icon_and_span">
      <FaYoutube className='icon_contact_www'/>
        <div className="div_span_contact_icon_www">
           <span><a href="https://www.youtube.com/@sunnurenergy">You Tube</a></span>
        </div>
      </div>
      <div className="internet_icon_and_span">
      <AiFillInstagram className='icon_contact_www'/>
        <div className="div_span_contact_icon_www">
          <span><a href="https://www.instagram.com/sunnur_energy_tech/">Instagram</a></span>
        </div>
      </div>
      <div className="internet_icon_and_span">
      <FaLocationDot className='icon_contact_www'/>
        <div className="div_span_contact_icon_www">
           <span><a href="#iframe_map_location">Location</a></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div id='iframe_map_location' className="contact_maps">
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d748.8846448343621!2d69.29690224799218!3d41.340645979279884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2s!4v1707824033178!5m2!1sru!2s"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48641.22087174098!2d70.78079134923145!3d40.36283240379578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ba8ca0439f3003%3A0x7721e582d2fc5f09!2z0K_QudC_0LDQvSwg0KTQtdGA0LPQsNC90YHQutCw0Y8g0J7QsdC70LDRgdGC0YwsINCj0LfQsdC10LrQuNGB0YLQsNC9!5e0!3m2!1sru!2s!4v1707824963559!5m2!1sru!2s"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>

<Form_input/>
<Footer/>
</div> : <div className='Contact_div'>
        <Navbar/>
        <div className="contact">
            <h1>Our contacts</h1>
            <div className="contact_div_colum">
                <div className="contact_div_colum_1">
                <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
                <BsPhoneFlip  className='contact_icon'/>
</div>
                    <h2>Telephone</h2>
                    <div className="contact_phone">
                    <p>Tel:</p> <span><a href="tel:+998 90 570 72 27">+998 90 570 72 27</a></span>
                    </div>
                    <div className="contact_phone">
                    <p>Tel:</p> <span><a href="tel:+998 99 805 35 67">+998 99 805 35 67</a></span>
                    </div>
                    <div className="contact_phone">
                    <p>Tel:</p> <span><a href="tel:+998 97 813 77 00">+998 97 813 77 00</a></span>
                    </div>
                </div>
                <div className="contact_div_colum_1">
                <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
                <IoLocationOutline className='contact_icon contact_icon2'/>
     </div>
                <div className='h33333333333333333'>
                      <h2>Location</h2>
                  <p className='h2_contact_p'>Address: 87rw+8Q7, Tashkent City,</p>
                  <p className='h2_contact_p'>Bogishamol Street, Uzbekistan,</p>
                  <p className='h2_contact_p'>LWN coworking</p>
                </div>
                  
                </div>
            </div>
        </div>

        <div className="internet_contact">
          <h1>Our social networks</h1>
          <div className="internet_div">
            <div className="internet_div_1">
              <div className="internet_icon_and_span">
              <FaTelegram className='teleg_contact_icon'/>
                <div className="div_span_contact_teleg">
                  <span><a href="https://t.me/sunnur_energy">Telegram group</a></span>
                </div>
              </div>
              <div className="internet_icon_and_span">
              <FaTelegram className='teleg_contact_icon'/>
                <div className="div_span_contact_teleg">
                  <span><a href="https://t.me/sunnur_energy">Telegram channel</a></span>
                </div>
              </div>
              <div className="internet_icon_and_span">
              <FaTelegram className='teleg_contact_icon'/>
                <div className="div_span_contact_teleg">
                  <span><a href="https://t.me/Oybek_6979">Telegram support</a></span>
                </div>
              </div>
            </div>
            <div className="internet_div_2">
              <div className="internet_icon_and_span">
              <FaYoutube className='icon_contact_www'/>
                <div className="div_span_contact_icon_www">
                   <span><a href="https://www.youtube.com/@sunnurenergy">You Tube</a></span>
                </div>
              </div>
              <div className="internet_icon_and_span">
              <AiFillInstagram className='icon_contact_www'/>
                <div className="div_span_contact_icon_www">
                  <span><a href="https://www.instagram.com/sunnur_energy_tech/">Instagram</a></span>
                </div>
              </div>
              <div className="internet_icon_and_span">
              <FaLocationDot className='icon_contact_www'/>
                <div className="div_span_contact_icon_www">
                   <span><a href="#iframe_map_location">Location</a></span>
                </div>
              </div>
            </div>
          </div>
        </div>
       <div id='iframe_map_location' className="contact_maps">
       <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d748.8846448343621!2d69.29690224799218!3d41.340645979279884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2s!4v1707824033178!5m2!1sru!2s"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48641.22087174098!2d70.78079134923145!3d40.36283240379578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ba8ca0439f3003%3A0x7721e582d2fc5f09!2z0K_QudC_0LDQvSwg0KTQtdGA0LPQsNC90YHQutCw0Y8g0J7QsdC70LDRgdGC0YwsINCj0LfQsdC10LrQuNGB0YLQsNC9!5e0!3m2!1sru!2s!4v1707824963559!5m2!1sru!2s"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
       </div>

        <Form_input/>
        <Footer/>
    </div>}
    </div>
  )
}
 