import React, { useEffect, useState } from 'react'
import "../css/Product.css"
import Footer from './Footer'
import Navbar from './Navbar'
import IMG18 from "../img/photo_2024-02-08_11-48-27.jpg"
import Form_input from './Form_input'

import AOS from "aos";
import "aos/dist/aos.css";

import { MdClear } from "react-icons/md";

export default function Product() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  var data_product_cop_cop = [
    {
      img: IMG18,
      name_two: "5 квт (АC)",      
      name: `Қуватлаш панеллари 5 квт (АC)`,
      title: "Йилига ток ишлаб чиқариш 9,125 квт",
      productInfo: {
        fullProductName: "Solar panel Longi /Jinkoa 550-565M ",
        fullProductType: "Inventor Solax 5 kW",
        fullProductType_2: "Профил (4mm* 2,5mm)",
        fullProductKabel: "Кабел 1*4 мм",
        fullProductEnergy: {
         energy_1: "Бир кунлик ишлаб чиқариш: 5кw * 7 соат = 35kw",
         energy_2: "Бир ойлик ишлаб чиқариш: 35kw * 30 кун = 1050kw"
        } ,
        fullProductAutoRegiretion: "Умумий пул микдори = 1050kw / 2 = 5250kw."
      }
    },
    {
      img: IMG18,
      name_two: "10 квт (АC)",      
      name: "Қуватлаш панеллари 10 квт (АC)",
      title: "Йилига ток ишлаб чиқариш 18,250 квт",
      productInfo: {
        fullProductName: "Solar panel Longi / Jinkoa 550-565M",
        fullProductType: "Inventor Solax 10 kW",
        fullProductType_2: "Профил (4mm* 2,5mm)",
        fullProductKabel: "Кабел 1*4 мм",
        fullProductEnergy: {
          energy_1: "Бир кунлик ишлаб чиқариш: 10кw * 7 соат = 70kw",
          energy_2: "Бир ойлик ишлаб чиқариш: 70kw * 30 кун = 2100kw"
         } ,
        fullProductAutoRegiretion: "Умумий пул микдори = 2100kw / 2 = 1050kw."
      }
    },
    {
      img: IMG18,
      name_two: "15 квт (АC)",      
      name: "Қуватлаш панеллари 15 квт (АC)",
      title: "Йилига ток ишлаб чиқариш 27,375 квт",
      productInfo: {
        fullProductName: "Solar panel Longi / Jinkoa 550-565M",
        fullProductType: "Inventor Solax 15 kW",
        fullProductType_2: "Профил (4mm* 2,5mm)",
        fullProductKabel: "Кабел 1*4 мм",
        fullProductEnergy: {
          energy_1: "Бир кунлик ишлаб чиқариш: 15кw * 7 соат = 105kw",
          energy_2: "Бир ойлик ишлаб чиқариш: 15кw * 7 соат = 105kw"
         } ,
        fullProductAutoRegiretion: "Умумий пул микдори = 3150kw / 2 = 1575kw."
      }
    },
    {
      img: IMG18,
      name_two: "30 квт (АC)",      
      name: "Қуватлаш панеллари 30 квт (АC)",
      title: "Йилига ток ишлаб чиқариш 54,750 квт",
      productInfo: {
        fullProductName: "Solar panel Longi / Jinkoa 550-565M",
        fullProductType: "Inventor Solax 30 kW",
        fullProductType_2: "Профил (4mm* 2,5mm)",
        fullProductKabel: "Кабел 1*4 мм",
        fullProductEnergy: {
          energy_1: "Бир кунлик ишлаб чиқариш: 30кw * 7 соат = 210kw",
          energy_2: "Бир ойлик ишлаб чиқариш: 210kw * 30 кун = 6300kw"
         } ,
        fullProductAutoRegiretion: "Умумий пул микдори = 6300kw / 2 = 3150kw."
      }
    },
    {
      img: IMG18,
      name_two: "50 квт (АC)",      
      name: "Қуватлаш панеллари 50 квт (АC)",
      title: "Йилига ток ишлаб чиқариш 91,250 квт",
      productInfo: {
        fullProductName: "Solar panel Longi / Jinkoa 550-565M",
        fullProductType: "Inventor Solax 50 kW",
        fullProductType_2: "Профил (4mm* 2,5mm)",
        fullProductKabel: "Кабел 1*4 мм",
        fullProductEnergy: {
          energy_1: "Бир кунлик ишлаб чиқариш: 50кw * 7 соат = 350kw",
          energy_2: "Бир ойлик ишлаб чиқариш: 350kw * 30 кун = 10,500kw"
         } ,
        fullProductAutoRegiretion: "Умумий пул микдори = 6300kw / 2 = 3150kw."
      }
    },
    {
      img: IMG18,
      name_two: "100 квт (АC)",      
      name: "Қуватлаш панеллари 100 квт (АC)",
      title: "Йилига ток ишлаб чиқариш 182,500 квт",
      productInfo: {
        fullProductName: "Solar panel Longi / Jinkoa 550-565M",
        fullProductType: "Inventor Solax 100 kW",
        fullProductType_2: "Профил (4mm* 2,5mm)",
        fullProductKabel: "Кабел 1*4 мм",
        fullProductEnergy: {
          energy_1: "Бир кунлик ишлаб чиқариш: 100кw * 7 соат = 700kw",
          energy_2: "Бир ойлик ишлаб чиқариш: 700kw * 30 кун = 21,000kw"
         } ,
        fullProductAutoRegiretion: "Умумий пул микдори = 6300kw / 2 = 3150kw."
      }
    }
  ] 
  var data_product_cop_cop_ENG1 = [
    {
      img: IMG18,
      name_two: "5 kW (АC)",      
      name: `Power panels 5 kW (AC)`,
      title: "Current production per year 9,125 kW",
      productInfo: {
        fullProductName: "Solar panel Longi /Jinkoa 550-565M ",
        fullProductType: "Inventor Solax 5 kW",
        fullProductType_2: "Profile (4mm * 2,5mm)",
        fullProductKabel: "Cable 1*4 mm",
        fullProductEnergy: {
         energy_1: "One-day production: 5kW * 7 hours = 35kW",
         energy_2: "One month production: 35kw * 30 days = 1050kw"
        } ,
        fullProductAutoRegiretion: "Total Money mix = 1050kw / 2 = 5250kw."
      }
    },
    {
      img: IMG18,
      name_two: "10 kW (АC)",      
      name: "Power panels 10 kW (АC)",
      title: "Current production per year 18,250 kW",
      productInfo: {
        fullProductName: "Solar panel Longi / Jinkoa 550-565M",
        fullProductType: "Inventor Solax 10 kW",
        fullProductType_2: "Profile (4mm * 2,5mm)",
        fullProductKabel: "Cable 1*4 mm",
        fullProductEnergy: {
          energy_1: "One-day production: 10kw * 7 hours = 70kW",
          energy_2: "One month production: 70kw * 30 days = 2100kw"
         } ,
        fullProductAutoRegiretion: "Total Money mix = 2100kw / 2 = 1050kw."
      }
    },
    {
      img: IMG18,
      name_two: "15 kW (АC)",      
      name: "Power panels 15 kW (АC)",
      title: "Current production per year 27,375 kW",
      productInfo: {
        fullProductName: "Solar panel Longi / Jinkoa 550-565M",
        fullProductType: "Inventor Solax 15 kW",
        fullProductType_2: "Profile (4mm * 2,5mm)",
        fullProductKabel: "Cable 1*4 mm",
        fullProductEnergy: {
          energy_1: "One-day production: 15kW * 7 hours = 105kW",
          energy_2: "One month production: 15kW * 7 hours = 105kW"
         } ,
        fullProductAutoRegiretion: "Total Money mix = 3150kw / 2 = 1575kw."
      }
    },
    {
      img: IMG18,
      name_two: "30 kW (АC)",      
      name: "Power panels 30 kW (АC)",
      title: "Current production per year 54,750 kW",
      productInfo: {
        fullProductName: "Solar panel Longi / Jinkoa 550-565M",
        fullProductType: "Inventor Solax 30 kW",
        fullProductType_2: "Profile (4mm * 2,5mm)",
        fullProductKabel: "Cable 1*4 mm",
        fullProductEnergy: {
          energy_1: "One-day production: 30KW * 7 hours = 210kW",
          energy_2: "One month production: 210kW * 30 days = 6300kw"
         } ,
        fullProductAutoRegiretion: "Total Money mix = 6300kw / 2 = 3150kw."
      }
    },
    {
      img: IMG18,
      name_two: "50 kW (АC)",      
      name: "Power panels 50 kW (АC)",
      title: "Current production per year 91,250 kW",
      productInfo: {
        fullProductName: "Solar panel Longi / Jinkoa 550-565M",
        fullProductType: "Inventor Solax 50 kW",
        fullProductType_2: "Profile (4mm * 2,5mm)",
        fullProductKabel: "Cable 1*4 mm",
        fullProductEnergy: {
          energy_1: "One-day production: 50KW * 7 hours = 350kW",
          energy_2: "One month production: 350kW * 30 days = 10,500kw"
         } ,
        fullProductAutoRegiretion: "Total Money mix = 6300kw / 2 = 3150kw."
      }
    },
    {
      img: IMG18,
      name_two: "100 kW (АC)",      
      name: "Power panels 100 kW (АC)",
      title: "Current production per year 182,500 kW",
      productInfo: {
        fullProductName: "Solar panel Longi / Jinkoa 550-565M",
        fullProductType: "Inventor Solax 100 kW",
        fullProductType_2: "Profile (4mm * 2,5mm)",
        fullProductKabel: "Cable 1*4 mm",
        fullProductEnergy: {
          energy_1: "One-day production: 100KW * 7 hours = 700kW",
          energy_2: "One month production: 700kW * 30 days = 21,000kw"
         } ,
        fullProductAutoRegiretion: "Total Money mix = 6300kw / 2 = 3150kw."
      }
    },
  ] 
  const [data_product_ENG, setDataProduct_ENG] = useState([
    {
      img: IMG18,
      name: `Power panels 5 kW (AC)`,
      title: "Current production per year 9,125 kW",
    },
    {
      img: IMG18,
      name: "Power panels 10 kW (AC)",
      title: "Current production per year 18,250 kW",
    },
    {
      img: IMG18,
      name: "Power panels 15 kW (AC)",
      title: "Current production per year 27,375 kW",
    },
    {
      img: IMG18,
      name: "Power panels 30 kW (AC)",
      title: "Current production per year 54,750 kW",
    },
    {
      img: IMG18,
      name: "Power panels 50 kW (AC)",
      title: "Current production per year 91,250 kW",
    },
    {
      img: IMG18,
      name: "Power panels 100 kW (AC)",
      title: "Current production per year 182,500 kW",
    },
  ])
  const [element, setElement] = useState(0)

  function productFunctionForm(key){
    setElement(key)
    document.querySelector('body').style = "overflow-y: hidden"
    document.querySelector(".productFormDesc").style = "z-index: 100; opacity: 1;"
  }
  function noneFormProduct(){
    document.querySelector(".productFormDesc").style = "z-index: -100; opacity: 0;"
    document.querySelector('body').style = "overflow-y: auto"
  }

  const [productLanguage, setProductLan] = useState(localStorage.getItem("lan"))

  return (
    <div>
      {productLanguage == 0 ? <div className='PRODUCT_div'>
<Navbar/>

<div className="product_header">
  <h1>Бизнинг <span>АC</span> ва <span>ДC</span> қуёш станцияларимиз</h1>
  <p>Қуёш панеллари <span>(1 клават)</span> дан <span>(100 мегаватт)</span> станцияларигача</p>
  <div data-aos="fade-up">
  <div className="product_header_button">
    <a href="#prduct_id"><button className='button_header_glava'>БАТАФСИЛ</button></a>
  </div>
  <div className="product_header_span">
 <span>Сиз станциянгиз нархини олдиндан <span>ҳисоблашингиз мумкин</span></span>
 </div>
  </div>
</div>
<div className="productFormDesc">
<MdClear onClick={() => {noneFormProduct()}} className='icon_product_form'/>

<div className="productIdForm">
  {element == 0 ? 
  
     <div className="divProductFormItem">
      <div className="divProductFormItem_img">
        <img src={data_product_cop_cop[0].img} alt="" />
        <h3>{data_product_cop_cop[0].name_two}</h3>
        <p>{data_product_cop_cop[0].title}</p>
      </div>
      <div className="divProductFormItem_map">
        <h3>{data_product_cop_cop[0].productInfo.fullProductName}</h3>
        <div className="divProductFormItem_map_div1">
          <div className="div_info_minDiv">
            <h4>Толиқ номи</h4>
            <p>{data_product_cop_cop[0].productInfo.fullProductType}</p>
          </div>
          <div className="div_info_minDiv">
            <h4>Профил</h4>
            <p>{data_product_cop_cop[0].productInfo.fullProductType_2}</p>
          </div>
          <div className="div_info_minDiv">
            <h4>Кабел</h4>
            <p>{data_product_cop_cop[0].productInfo.fullProductKabel}</p>
          </div>
        </div>
        <div className="divProductFormItem_map_div2">
          <h4>Ишлаб чиқариш</h4>
          <p>{data_product_cop_cop[0].productInfo.fullProductEnergy.energy_1}</p>
          <p>{data_product_cop_cop[0].productInfo.fullProductEnergy.energy_2}</p>
        </div>
        </div>
    </div>

  : element == 1 ? 
  
     <div className="divProductFormItem">
      <div className="divProductFormItem_img">
        <img src={data_product_cop_cop[1].img} alt="" />
        <h3>{data_product_cop_cop[1].name_two}</h3>
        <p>{data_product_cop_cop[1].title}</p>
      </div>
      <div className="divProductFormItem_map">
        <h3>{data_product_cop_cop[1].productInfo.fullProductName}</h3>
        <div className="divProductFormItem_map_div1">
          <div className="div_info_minDiv">
            <h4>Толиқ номи</h4>
            <p>{data_product_cop_cop[1].productInfo.fullProductType}</p>
          </div>
          <div className="div_info_minDiv">
            <h4>Профил</h4>
            <p>{data_product_cop_cop[1].productInfo.fullProductType_2}</p>
          </div>
          <div className="div_info_minDiv">
            <h4>Кабел</h4>
            <p>{data_product_cop_cop[1].productInfo.fullProductKabel}</p>
          </div>
        </div>
        <div className="divProductFormItem_map_div2">
          <h4>Ишлаб чиқариш</h4>
          <p>{data_product_cop_cop[1].productInfo.fullProductEnergy.energy_1}</p>
          <p>{data_product_cop_cop[1].productInfo.fullProductEnergy.energy_2}</p>
        </div>
        </div>
    </div>

  : element == 2 ? 
  
     <div className="divProductFormItem">
      <div className="divProductFormItem_img">
        <img src={data_product_cop_cop[2].img} alt="" />
        <h3>{data_product_cop_cop[2].name_two}</h3>
        <p>{data_product_cop_cop[2].title}</p>
      </div>
      <div className="divProductFormItem_map">
        <h3>{data_product_cop_cop[2].productInfo.fullProductName}</h3>
        <div className="divProductFormItem_map_div1">
          <div className="div_info_minDiv">
            <h4>Толиқ номи</h4>
            <p>{data_product_cop_cop[2].productInfo.fullProductType}</p>
          </div>
          <div className="div_info_minDiv">
            <h4>Профил</h4>
            <p>{data_product_cop_cop[2].productInfo.fullProductType_2}</p>
          </div>
          <div className="div_info_minDiv">
            <h4>Кабел</h4>
            <p>{data_product_cop_cop[2].productInfo.fullProductKabel}</p>
          </div>
        </div>
        <div className="divProductFormItem_map_div2">
          <h4>Ишлаб чиқариш</h4>
          <p>{data_product_cop_cop[2].productInfo.fullProductEnergy.energy_1}</p>
          <p>{data_product_cop_cop[2].productInfo.fullProductEnergy.energy_2}</p>
        </div>
        </div>
    </div>
 
  : element == 3 ?
  
     <div className="divProductFormItem">
      <div className="divProductFormItem_img">
        <img src={data_product_cop_cop[3].img} alt="" />
        <h3>{data_product_cop_cop[3].name_two}</h3>
        <p>{data_product_cop_cop[3].title}</p>
      </div>
      <div className="divProductFormItem_map">
        <h3>{data_product_cop_cop[3].productInfo.fullProductName}</h3>
        <div className="divProductFormItem_map_div1">
          <div className="div_info_minDiv">
            <h4>Толиқ номи</h4>
            <p>{data_product_cop_cop[3].productInfo.fullProductType}</p>
          </div>
          <div className="div_info_minDiv">
            <h4>Профил</h4>
            <p>{data_product_cop_cop[3].productInfo.fullProductType_2}</p>
          </div>
          <div className="div_info_minDiv">
            <h4>Кабел</h4>
            <p>{data_product_cop_cop[3].productInfo.fullProductKabel}</p>
          </div>
        </div>
        <div className="divProductFormItem_map_div2">
          <h4>Ишлаб чиқариш</h4>
          <p>{data_product_cop_cop[3].productInfo.fullProductEnergy.energy_1}</p>
          <p>{data_product_cop_cop[3].productInfo.fullProductEnergy.energy_2}</p>
        </div>
        </div>
    </div>

  : element == 4 ? 
  <div className="divProductFormItem">
  <div className="divProductFormItem_img">
    <img src={data_product_cop_cop[4].img} alt="" />
    <h3>{data_product_cop_cop[4].name_two}</h3>
    <p>{data_product_cop_cop[4].title}</p>
  </div>
  <div className="divProductFormItem_map">
    <h3>{data_product_cop_cop[4].productInfo.fullProductName}</h3>
    <div className="divProductFormItem_map_div1">
      <div className="div_info_minDiv">
        <h4>Толиқ номи</h4>
        <p>{data_product_cop_cop[4].productInfo.fullProductType}</p>
      </div>
      <div className="div_info_minDiv">
        <h4>Профил</h4>
        <p>{data_product_cop_cop[4].productInfo.fullProductType_2}</p>
      </div>
      <div className="div_info_minDiv">
        <h4>Кабел</h4>
        <p>{data_product_cop_cop[4].productInfo.fullProductKabel}</p>
      </div>
    </div>
    <div className="divProductFormItem_map_div2">
      <h4>Ишлаб чиқариш</h4>
      <p>{data_product_cop_cop[4].productInfo.fullProductEnergy.energy_1}</p>
      <p>{data_product_cop_cop[4].productInfo.fullProductEnergy.energy_2}</p>
    </div>
    </div>
</div> 
  : element == 5 ? 
  <div className="divProductFormItem">
  <div className="divProductFormItem_img">
    <img src={data_product_cop_cop[5].img} alt="" />
    <h3>{data_product_cop_cop[5].name_two}</h3>
    <p>{data_product_cop_cop[5].title}</p>
  </div>
  <div className="divProductFormItem_map">
    <h3>{data_product_cop_cop[5].productInfo.fullProductName}</h3>
    <div className="divProductFormItem_map_div1">
      <div className="div_info_minDiv">
        <h4>Толиқ номи</h4>
        <p>{data_product_cop_cop[5].productInfo.fullProductType}</p>
      </div>
      <div className="div_info_minDiv">
        <h4>Профил</h4>
        <p>{data_product_cop_cop[5].productInfo.fullProductType_2}</p>
      </div>
      <div className="div_info_minDiv">
        <h4>Кабел</h4>
        <p>{data_product_cop_cop[5].productInfo.fullProductKabel}</p>
      </div>
    </div>
    <div className="divProductFormItem_map_div2">
      <h4>Ишлаб чиқариш</h4>
      <p>{data_product_cop_cop[5].productInfo.fullProductEnergy.energy_1}</p>
      <p>{data_product_cop_cop[5].productInfo.fullProductEnergy.energy_2}</p>
    </div>
    </div>
</div>
  : ""}
</div>
</div>



<div id='prduct_id' className="product_block_1">
  <h1 className='product_block_1_h1h1'>Бизнес учун <span>панеллар</span></h1>
  <div className="product_div">
    {data_product_cop_cop.map((item,key) => {
      return <div>
      <div data-aos="fade-up"><div className="product_div_1">
      <div className="product_img"><img src={item.img} alt="" /></div>
      <h3>{item.name}</h3>
      <p>{item.title}</p>
      <div onClick={() => {productFunctionForm(key)}} className="product_button"><button>ТОЛИҚ МАЛУМОТ</button></div>
    </div></div>
      </div>
    })}  
  </div>

  
</div>

<Form_input/>

<Footer/>
</div> : <div className='PRODUCT_div'>
        <Navbar/>

        <div className="product_header">
          <h1>Our <span>AC</span> and <span>DC</span> groom our stations</h1>
          <p>Solar panels <span>(1 clavate)</span> to <span>(100 megawatts)</span> stations</p>
          <div data-aos="fade-up">
          <div className="product_header_button">
            <a href="#prduct_id"><button className='button_header_glava'>MORE</button></a>
          </div>
          <div className="product_header_span">
         <span>You can calculate the cost of<span>  your station in advance</span></span>
         </div>
          </div>
        </div>
        <div className="productFormDesc">
        <MdClear onClick={() => {noneFormProduct()}} className='icon_product_form'/>
        
        <div className="productIdForm">
          {element == 0 ? 
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop_ENG1[0].img} alt="" />
                <h3>{data_product_cop_cop_ENG1[0].name_two}</h3>
                <p>{data_product_cop_cop_ENG1[0].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop_ENG1[0].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Full name</h4>
                    <p>{data_product_cop_cop_ENG1[0].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Profile</h4>
                    <p>{data_product_cop_cop_ENG1[0].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Cable</h4>
                    <p>{data_product_cop_cop_ENG1[0].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Production</h4>
                  <p>{data_product_cop_cop_ENG1[0].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop_ENG1[0].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                </div>
            </div>
        
          : element == 1 ? 
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop_ENG1[1].img} alt="" />
                <h3>{data_product_cop_cop_ENG1[1].name_two}</h3>
                <p>{data_product_cop_cop_ENG1[1].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop_ENG1[1].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Full name</h4>
                    <p>{data_product_cop_cop_ENG1[1].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Profile</h4>
                    <p>{data_product_cop_cop_ENG1[1].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Cable</h4>
                    <p>{data_product_cop_cop_ENG1[1].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Production</h4>
                  <p>{data_product_cop_cop_ENG1[1].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop_ENG1[1].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                </div>
            </div>
        
          : element == 2 ? 
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop_ENG1[2].img} alt="" />
                <h3>{data_product_cop_cop_ENG1[2].name_two}</h3>
                <p>{data_product_cop_cop_ENG1[2].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop_ENG1[2].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Full name</h4>
                    <p>{data_product_cop_cop_ENG1[2].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Profile</h4>
                    <p>{data_product_cop_cop_ENG1[2].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Cable</h4>
                    <p>{data_product_cop_cop_ENG1[2].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Production</h4>
                  <p>{data_product_cop_cop_ENG1[2].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop_ENG1[2].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                </div>
            </div>
         
          : element == 3 ?
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop_ENG1[3].img} alt="" />
                <h3>{data_product_cop_cop_ENG1[3].name_two}</h3>
                <p>{data_product_cop_cop_ENG1[3].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop_ENG1[3].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Full name</h4>
                    <p>{data_product_cop_cop_ENG1[3].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Profile</h4>
                    <p>{data_product_cop_cop_ENG1[3].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Cable</h4>
                    <p>{data_product_cop_cop_ENG1[3].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Production</h4>
                  <p>{data_product_cop_cop_ENG1[3].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop_ENG1[3].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                </div>
            </div>
       
          : element == 4 ? 
          <div className="divProductFormItem">
          <div className="divProductFormItem_img">
            <img src={data_product_cop_cop[4].img} alt="" />
            <h3>{data_product_cop_cop[4].name_two}</h3>
            <p>{data_product_cop_cop[4].title}</p>
          </div>
          <div className="divProductFormItem_map">
            <h3>{data_product_cop_cop[4].productInfo.fullProductName}</h3>
            <div className="divProductFormItem_map_div1">
              <div className="div_info_minDiv">
                <h4>Full name</h4>
                <p>{data_product_cop_cop[4].productInfo.fullProductType}</p>
              </div>
              <div className="div_info_minDiv">
                <h4>Profile</h4>
                <p>{data_product_cop_cop[4].productInfo.fullProductType_2}</p>
              </div>
              <div className="div_info_minDiv">
                <h4>Cable</h4>
                <p>{data_product_cop_cop[4].productInfo.fullProductKabel}</p>
              </div>
            </div>
            <div className="divProductFormItem_map_div2">
              <h4>Production</h4>
              <p>{data_product_cop_cop[4].productInfo.fullProductEnergy.energy_1}</p>
              <p>{data_product_cop_cop[4].productInfo.fullProductEnergy.energy_2}</p>
            </div>
            </div>
        </div>
        : element == 5 ? 
        <div className="divProductFormItem">
        <div className="divProductFormItem_img">
          <img src={data_product_cop_cop[5].img} alt="" />
          <h3>{data_product_cop_cop[5].name_two}</h3>
          <p>{data_product_cop_cop[5].title}</p>
        </div>
        <div className="divProductFormItem_map">
          <h3>{data_product_cop_cop[5].productInfo.fullProductName}</h3>
          <div className="divProductFormItem_map_div1">
            <div className="div_info_minDiv">
              <h4>Full name</h4>
              <p>{data_product_cop_cop[5].productInfo.fullProductType}</p>
            </div>
            <div className="div_info_minDiv">
              <h4>Profile</h4>
              <p>{data_product_cop_cop[5].productInfo.fullProductType_2}</p>
            </div>
            <div className="div_info_minDiv">
              <h4>Cable</h4>
              <p>{data_product_cop_cop[5].productInfo.fullProductKabel}</p>
            </div>
          </div>
          <div className="divProductFormItem_map_div2">
            <h4>Production</h4>
            <p>{data_product_cop_cop[5].productInfo.fullProductEnergy.energy_1}</p>
            <p>{data_product_cop_cop[5].productInfo.fullProductEnergy.energy_2}</p>
          </div>
          </div>
      </div> 
       : ""}
        </div>
        </div>


        <div id='prduct_id' className="product_block_1">
          <h1 className='product_block_1_h1h1'>Panels for <span>business</span></h1>
          <div className="product_div">
            {data_product_ENG.map((item,key) => {
              return <div>
              <div data-aos="fade-up"><div className="product_div_1">
              <div className="product_img"><img src={item.img} alt="" /></div>
              <h3>{item.name}</h3>
              <p>{item.title}</p>
              <div onClick={() => {productFunctionForm(key)}} className="product_button"><button>MORE DETAILS</button></div>
            </div></div>
              </div>
            })}  
          </div>
        </div>

        <Form_input/>

        <Footer/>
    </div>}
    </div>
  )
}
