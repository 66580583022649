import React, { useEffect, useState } from 'react'
import "../css/Proect.css"
import Footer from './Footer'
import Navbar from './Navbar'

import AOS from "aos";
import "aos/dist/aos.css";

import { FaSolarPanel } from "react-icons/fa";

import IMG_PROECT1 from "../img/photo_2024-02-13_23-05-44.jpg"
import IMG_PROECT2 from "../img/photo_2024-02-13_23-06-06.jpg"
import IMG_PROECT3 from "../img/photo_2024-02-13_23-06-41.jpg"
import IMG_PROECT4 from "../img/photo_2024-02-13_23-06-53.jpg"
import IMG_PROECT5 from "../img/photo_2024-02-13_23-05-30.jpg"
import IMG_PROECT6 from "../img/photo_2024-02-13_23-05-37.jpg"
import IMG_PROECT7 from "../img/photo_2024-02-13_23-05-40.jpg"
import IMG_PROECT8 from "../img/photo_2024-02-13_23-07-04.jpg"

export default function Product() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [proectLang, setProectLang] = useState(localStorage.getItem("lan"))
  return (
    <div>
      {proectLang == 0 ? <div className='Proect_div'>
<Navbar/>

<div className="product_header proect_header">
  <h1>Бизнинг тугалланган <span>лойиҳаларимиз</span></h1>
  <p>1 квт дан 100 квт гача</p>
  <div data-aos="fade-up">
  <div className="product_header_button">
   <a href="#home_scroll"><button>БАТАФСИЛ</button></a>
  </div>
  </div>
</div>
<div className="home" id='home_scroll'>
 
  <h1>Ўзбекистон бўйлаб <span>700 дан ортик електр стансияларини ўрнатдик</span></h1>
  <div className="home_div">
    <img className='IMG_PROECT_KEY' src={IMG_PROECT1} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT2} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT3} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT4} alt="" />
</div>
<div className="home_div">
    <img className='IMG_PROECT_KEY' src={IMG_PROECT5} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT6} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT7} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT8} alt="" />
</div>
{/* <div data-aos="fade-up"><div className="home_div_tegi home_div_tegi_3">
<h3>Бизнес марказлар</h3>
<p>Ходимлар иш куни давомида керакли курилмани заряд қилишлари мумкин</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div> */}
{/* <div className="home_div">

<div className="home_div_1">
<div data-aos="fade-up"><div className="home_div_tegi home_div_tegi_3">
<h3>Бизнес марказлар</h3>
<p>Ходимлар иш куни давомида керакли курилмани заряд қилишлари мумкин</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

</div>
<div className="home_div_tegi home_div_tegi_2"> <div data-aos="fade-up">
<h3>Бизнес марказлар</h3>
<p>Ходимлар иш куни давомида керакли курилмани заряд қилишлари мумкин</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="home_div_2">
<div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
<h3>Меҳмонхона ва ресторан</h3>
<p>Куйош панеллари ташриф буюрувчилар сонини кўпайтиради ва муассаса обрўсини оширади</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

</div>
<div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up"> 
<h3>Меҳмонхона ва ресторан</h3>
<p>Куйош панеллари ташриф буюрувчилар сонини кўпайтиради ва муассаса обрўсини оширади</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="home_div_3">
<div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
<h3>Завод ва фабрикалар</h3>
<p>Давлат аллақачон зарядлаш станцияларини ўрнатиш тўғрисидаги қонунни<br />ўрнатган</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="div_white_none"></div>
</div>
<div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up"> 
<h3>Завод ва фабрикалар</h3>
<p>Давлат аллақачон зарядлаш станцияларини ўрнатиш тўғрисидаги қонунни<br className='br_1'/>ўрнатган</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="home_div_4">
<div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
<h3>Уй ва тўхташ жойлар</h3>
<p>Бу уйда фойдаланиш ва тўхташ жойларини тарғиб қилиш учун қулай</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div> </div>

</div>
<div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up">
<h3>Уй ва тўхташ жойлар</h3>
<p>Бу уйда фойдаланиш ва тўхташ жойларини тарғиб қилиш учун қулай</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

</div>
<div className="home_div">

<div className="home_div_1">
<div data-aos="fade-up"><div className="home_div_tegi home_div_tegi_3">
<h3>Бизнес марказлар</h3>
<p>Ходимлар иш куни давомида керакли курилмани заряд қилишлари мумкин</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

</div>
<div className="home_div_tegi home_div_tegi_2"> <div data-aos="fade-up">
<h3>Бизнес марказлар</h3>
<p>Ходимлар иш куни давомида керакли курилмани заряд қилишлари мумкин</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="home_div_2">
<div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
<h3>Меҳмонхона ва ресторан</h3>
<p>Куйош панеллари ташриф буюрувчилар сонини кўпайтиради ва муассаса обрўсини оширади</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

</div>
<div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up"> 
<h3>Меҳмонхона ва ресторан</h3>
<p>Куйош панеллари ташриф буюрувчилар сонини кўпайтиради ва муассаса обрўсини оширади</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="home_div_3">
<div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
<h3>Завод ва фабрикалар</h3>
<p>Давлат аллақачон зарядлаш станцияларини ўрнатиш тўғрисидаги қонунни<br />ўрнатган</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="div_white_none"></div>
</div>
<div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up"> 
<h3>Завод ва фабрикалар</h3>
<p>Давлат аллақачон зарядлаш станцияларини ўрнатиш тўғрисидаги қонунни<br className='br_1'/>ўрнатган</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="home_div_4">
<div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
<h3>Уй ва тўхташ жойлар</h3>
<p>Бу уйда фойдаланиш ва тўхташ жойларини тарғиб қилиш учун қулай</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div> </div>

</div>
<div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up">
<h3>Уй ва тўхташ жойлар</h3>
<p>Бу уйда фойдаланиш ва тўхташ жойларини тарғиб қилиш учун қулай</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

</div>
<div className="home_div">

<div className="home_div_1">
<div data-aos="fade-up"><div className="home_div_tegi home_div_tegi_3">
<h3>Бизнес марказлар</h3>
<p>Ходимлар иш куни давомида керакли курилмани заряд қилишлари мумкин</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

</div>
<div className="home_div_tegi home_div_tegi_2"> <div data-aos="fade-up">
<h3>Бизнес марказлар</h3>
<p>Ходимлар иш куни давомида керакли курилмани заряд қилишлари мумкин</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="home_div_2">
<div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
<h3>Меҳмонхона ва ресторан</h3>
<p>Куйош панеллари ташриф буюрувчилар сонини кўпайтиради ва муассаса обрўсини оширади</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

</div>
<div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up"> 
<h3>Меҳмонхона ва ресторан</h3>
<p>Куйош панеллари ташриф буюрувчилар сонини кўпайтиради ва муассаса обрўсини оширади</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="home_div_3">
<div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
<h3>Завод ва фабрикалар</h3>
<p>Давлат аллақачон зарядлаш станцияларини ўрнатиш тўғрисидаги қонунни<br />ўрнатган</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="div_white_none"></div>
</div>
<div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up"> 
<h3>Завод ва фабрикалар</h3>
<p>Давлат аллақачон зарядлаш станцияларини ўрнатиш тўғрисидаги қонунни<br className='br_1'/>ўрнатган</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

<div className="home_div_4">
<div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
<h3>Уй ва тўхташ жойлар</h3>
<p>Бу уйда фойдаланиш ва тўхташ жойларини тарғиб қилиш учун қулай</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div> </div>

</div>
<div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up">
<h3>Уй ва тўхташ жойлар</h3>
<p>Бу уйда фойдаланиш ва тўхташ жойларини тарғиб қилиш учун қулай</p>
<div className='home_icon'>
<FaSolarPanel />
</div>
</div></div>

</div> */}
</div>
<Footer/>
</div>:<div className='Proect_div'>
        <Navbar/>

        <div className="product_header proect_header">
          <h1>Our completed <span>projects</span></h1>
          <p>1 kW to 100 kW</p>
          <div data-aos="fade-up">
          <div className="product_header_button">
           <a href="#home_scroll"><button>MORE</button></a>
          </div>
          </div>
        </div>
        <div className="home" id='home_scroll'>
         
          <h1>We have <span>installed more than 700 power plants</span> throughout Uzbekistan</h1>
          <div className="home_div">
    <img className='IMG_PROECT_KEY' src={IMG_PROECT1} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT2} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT3} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT4} alt="" />
</div>
<div className="home_div">
    <img className='IMG_PROECT_KEY' src={IMG_PROECT5} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT6} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT7} alt="" />
    <img className='IMG_PROECT_KEY' src={IMG_PROECT8} alt="" />
</div>
        </div>
        
        <Footer/>
    </div>}
    </div>
  )
}
