import React, { useState } from 'react'
import "../css/Blog.css"
import Footer from './Footer'
import Navbar from './Navbar'
import { MdClear } from "react-icons/md";

import IMG1_BLOG from "../img/solar_panels.jpg"
import IMG3_BLOG from "../img/Night-Time-Solar-Panels-1.jpg"
import IMG2_BLOG from "../img/GettyImages-1225475441-18a762667235461abfbdc58d7efc63bd.jpg"
import IMG4_BLOG from "../img/photo_2024-03-05_22-33-12.jpg"
import IMG5_BLOG from "../img/photo_2024-03-05_22-39-59.jpg"
import IMG6_BLOG from "../img/photo_2024-03-05_22-22-19.jpg"

export default function Blog() {
    const [blogLan, setBlogLAn] = useState(localStorage.getItem("lan"))

    function blog_function_form_block(){
        document.querySelector(".BlogMoreDiv").style = "z-index: 120; opacity: 1;"
        document.querySelector("body").style = "overflow-y: hidden;"
    }
    function blog_function_form_none() {
        document.querySelector(".BlogMoreDiv").style = "z-index: -120; opacity: 0;"
        document.querySelector("body").style = "overflow-y: scroll;"
    }
    function blog_function_form_block2(){
        document.querySelector(".BlogMoreDiv2").style = "z-index: 120; opacity: 1;"
        document.querySelector("body").style = "overflow-y: hidden;"
    }
    function blog_function_form_none2() {
        document.querySelector(".BlogMoreDiv2").style = "z-index: -120; opacity: 0;"
        document.querySelector("body").style = "overflow-y: scroll;"
    }
    function blog_function_form_block3(){
        document.querySelector(".BlogMoreDiv3").style = "z-index: 120; opacity: 1;"
        document.querySelector("body").style = "overflow-y: hidden;"
    }
    function blog_function_form_none3() {
        document.querySelector(".BlogMoreDiv3").style = "z-index: -120; opacity: 0;"
        document.querySelector("body").style = "overflow-y: scroll;"
    }


  return (
   <div>
    {blogLan == 0 ?  <div className='Blog_div'>
<Navbar/>
<div className="BlogMoreDiv">
<MdClear onClick={() => {blog_function_form_none()}} className="blog_div_none_icon" />

    <div className="blog_div_img_form_iv">
    <img src={IMG4_BLOG} alt="" />
    </div>
    <p>Деградация ва Кафолат: Tiger Нео панеллари биринчи йили ≤1% ва кейинги йилларда -0.4% линей дегредацияни таклиф етади, бу еса 30 йил давомида юқори иш фаолиятини кафолатлайди. Tiger Про ҳам 30 йиллик кучланиш кафолатига ега.</p>
    <p>Технология: Tiger Нео, Топcон (Tunnel Oxide Пассиватед Contact) технологиясидан фойдаланади, 
Tiger Про еса ТР (Tiling Ribbon) ва МББ (Multi Busbar) бу еса қувват йўқотишини камайтиришга ёрдам беради.</p>
    <p>Tiger Нео серияси юқори самарадорлик ва юқори ҳарорат шароитида ишлай олади. Tiger Про еса юқори қувватли ва катта ҳажмдаги лойиҳалар учун қулай.

Мусаффо енергия - мусаффо ҳаёт.</p>
</div>
<div className="BlogMoreDiv2">
<MdClear onClick={() => {blog_function_form_none2()}} className="blog_div_none_icon" />

    <div className="blog_div_img_form_iv">
    <img src={IMG5_BLOG} alt="" />
    </div>
    <p>ИП21
Қаттиқ жисмлар (дм12.5 мм дан кичик жисмлар) кириб келишига қарши ҳимоя қилади.
Сув томчилари вертикал ҳолатдан енгил бурчакда (15° ичида) тушганда қурилмани ҳимоя қилишини кўрсатади.</p>
    <p>ИП65
 - (6) қурилма тўлиқ чангга қарши ҳимояланганини билдиради, chang ичкарига кирмайди
 - (5) қурилма сувга қарши ҳимояланганини англатади.</p>
    <p>ИП66
 - (6) Ип65даги каби, қурилма тўлиқ чангга қарши ҳимояланган.
 - (6) қурилма кучли сувга қарши ҳимояланган. ИП66 Ип65га қараганда сув босимига бардошли.

ИП21 minimal ҳимоя қилади, ички фойдаланиш учун, ИП65 chang ва сув қарши яхши ҳимоя таъминлайди, ИП66 еса юқори босимли сувга чидамли.

Мусаффо енергия - мусаффо ҳаёт</p>
</div>
<div className="BlogMoreDiv3">
<MdClear onClick={() => {blog_function_form_none3()}} className="blog_div_none_icon" />

    <div className="blog_div_img_form_iv">
    <img src={IMG6_BLOG} alt="" />
    </div>
    <p>4. Иқтисодий фойда: Қуёш енергияси тизимларини ўрнатиш ва експлуатация қилиш харажатларини пасайтиради.</p>
    <p>5. Узун муддатли инвестиция: Қуёш панелининг фойдаланиш муддати 25 йилдан 30 йилгача.
Узоқ муддатли даромад келтиришини таъминлайди.</p>
    <p>6. Ҳукумат қуёш енергиясига инвестиция қилишни рағбатлантириш мақсадида солиқ имтиёзлари, субсидиялар бермоқда.

Агар сизнинг 10 кw қуёш стансиянгиз йилига 15,000 - 18,000 кwҳ гача енергия ишлаб чиқарса ва електр енергиянинг нархи 0.07 dollar/кwҳ бўлса, йиллик даромадингиз тахминан 1,050$ - 1,260$ гача бўлиши мумкин.

Мусаффо енергия - мусаффо ҳаёт</p>
</div>
<div className="product_header blog_header">
  <h1><span>Қуёш Енергия Янгиликлари</span></h1>
</div>

<div className="blog_news_div">
    <div className="blog_line_div"></div>
    <h1 className='blog_news_div_h1'>Биз дунёни тоза қиламиз, табиий ресурсларни <span>тежаймиз ва одамларга қулайлик яратамиз</span></h1>
    <div className="blog_line_div"></div>

    <div className="blog_header_news_div_new">
        <div className="blog_div_1">
        <div className="news_div_img">
            <img src={IMG4_BLOG} alt="" />
        </div>
        <div className="news_metaTeg">
            <h1>Jinko Tiger Pro VS Jinko Tiger Neo</h1>
            <p>Қувват ва Самарадорлик: Tiger Про 21.4% Tiger Нео еса 23.23% Ҳарорат Коеффициенти: Tiger Нео юқори ҳароратларда ишлай олади.Нео серияси бу борада яхшироқ кўрсаткичларга ега.Бифаcиал Текнологияси: Tiger Нео серияси, 85% гача бифаcиал самарадорликка ега ва ёруғликни орқадан ҳам самарали қабул қилади.</p>
        </div>
        </div>
        <div onClick={() => {blog_function_form_block()}} className="blog_div_button"><button>БАТАФСИЛ</button></div>
        
    </div>
    <div className="blog_header_news_div_new">
        <div className="blog_div_1">
        <div className="news_div_img">
            <img src={IMG5_BLOG} alt="" />
        </div>
        <div className="news_metaTeg">
            <h1>ИП (Ingress ҳимояси) рейтинги</h1>
            <p>Електрон қурилмаларнинг chang, сув ташқи таъсирларга қаршилик даражасини кўрсатади. ИП рейтинги икки рақам билан ифодаланади: биринчи - қаттиқ жисмлар(chang) кириб келишига қарши ҳимоя, иккинчи - сув кириб келишига қарши ҳимояни. ИП21, ИП65 ва ИП66 солиштирамиз.Қаттиқ жисмлар (дм12.5 мм дан кичик жисмлар) кириб келишига қарши ҳимоя қилади.</p>
        </div>
        </div>
        <div onClick={() => {blog_function_form_block2()}} className="blog_div_button"><button>БАТАФСИЛ</button></div>
    </div>
    <div className="blog_header_news_div_new">
        <div className="blog_div_1">
        <div className="news_div_img">
            <img src={IMG6_BLOG} alt="" />
        </div>
        <div className="news_metaTeg">
            <h1>Қуёш стансиясига инвестиция қилиб қанча даромад кўрса бўлади?</h1>
            <p>1. Екологик тоза енергия: Қуёш енергияси тоза, янгиланувчи енергиядир. У атмосферага зарарли газларни чиқармайди.

2. Чексиз енергия манбаи: Қуёш енергияси деярли чексиз ҳисобланади. 

3. Енергия мустақиллиги: Қуёш стансиялари мамлакатнинг енергия импортига боғлиқлигини камайтиради.</p>
        </div>
        </div>
        <div onClick={() => {blog_function_form_block3()}} className="blog_div_button"><button>БАТАФСИЛ</button></div>
    </div>
</div>
<Footer/>
</div>  :  <div className='Blog_div'>
        <Navbar/>
        <div className="BlogMoreDiv">
<MdClear onClick={() => {blog_function_form_none()}} className="blog_div_none_icon" />

    <div className="blog_div_img_form_iv">
    <img src={IMG4_BLOG} alt="" />
    </div>
    <p>Degradation and warranty: Tiger Neo panels offer ≤1% linear degradation in the first year and -0.4% linear degradation in subsequent years, which guarantees high performance over 30 years. The Tiger Pro also has a 30-year voltage guarantee.</p>
    <p>Technology: Tiger Neo, uses TOPCon (Tunnel Oxide Passivated Contact) technology, 
And Tiger Pro TR (Tiling Ribbon) and MBB (Multi Busbar) which help reduce power loss.</p>
    <p>The Tiger Neo series can operate in high efficiency and high temperature conditions. And Tiger Pro is convenient for high-power and large-scale projects.
Pure energy-Pure Life</p>
</div>
<div className="BlogMoreDiv2">
<MdClear onClick={() => {blog_function_form_none2()}} className="blog_div_none_icon" />

    <div className="blog_div_img_form_iv">
    <img src={IMG5_BLOG} alt="" />
    </div>
    <p>IP21
Protects against intrusion by solid objects (bodies smaller than dm12.5 mm).
Indicates that the water droplets protect the device when falling at a slight angle (within 15°) from the vertical position.</p>
    <p>IP65
 - (6) means that the device is fully protected against dust, dust does not penetrate inside
 - (5) means that the device is protected against water.</p>
    <p>IP66
 - (6) as in IP65, the device is fully protected against dust.
 - (6) the device is protected against Strong Water. IP66 is more resistant to water pressure than IP65.

IP21 provides minimal protection, for domestic use, IP65 provides good protection against dust and water, while IP66 is resistant to high pressure water.

Pure energy-Pure Life</p>
</div>
<div className="BlogMoreDiv3">
<MdClear onClick={() => {blog_function_form_none3()}} className="blog_div_none_icon" />

    <div className="blog_div_img_form_iv">
    <img src={IMG6_BLOG} alt="" />
    </div>
    <p>4. Economic benefits: reduces the cost of installing and operating solar energy systems.</p>
    <p>5. Long-term investment: the period of use of the solar panel is from 25 to 30 years.
Ensures long-term income.</p>
    <p>
6. The government is providing tax incentives, subsidies in order to encourage investment in solar energy.
If your 10 kW solar plant generates up to 15,000 - 18,000 kWh of energy per year and the cost of electricity is$ 0.07/kWh, your annual income can be around$ 1,050 - $ 1,260.
Pure energy-Pure Life</p>
</div>

        <div className="product_header blog_header">
          <h1>< span >Sunnur energy news</span></h1>
        </div>

        <div className="blog_news_div">
            <div className="blog_line_div"></div>
            <h1 className='blog_news_div_h1'>We make the <span>world clean, save natural resources and make</span> people comfortable</h1>
            <div className="blog_line_div"></div>

            <div className="blog_header_news_div_new">
                <div className="blog_div_1">
                <div className="news_div_img">
                    <img src={IMG4_BLOG} alt="" />
                </div>
                <div className="news_metaTeg">
                    <h1>Jinko Tiger Pro VS Jinko Tiger Neo</h1>
                    <p>Power and efficiency: Tiger Pro 21.4% Tiger Neo while 23.23% temperature coefficient: Tiger Neo can work at high temperatures.The Neo series has better performance in this regard.Bifacial technology: Tiger Neo series, has up to 85% bifacial efficiency and absorbs light more efficiently than behind.</p>
                </div>
                </div>
                <div onClick={() => {blog_function_form_block()}} className="blog_div_button"><button>MORE</button></div>
                
            </div>
            <div className="blog_header_news_div_new">
                <div className="blog_div_1">
                <div className="news_div_img">
                    <img src={IMG5_BLOG} alt="" />
                </div>
                <div className="news_metaTeg">
                    <h1>IP (Ingress protection) rating</h1>
                    <p>Dust, water of electronic devices indicate the level of resistance to external influences. The IP rating is represented by two numbers: the first - protection against the penetration of solid objects(dust), the second - protection against the penetration of water. Compare IP21, IP65 and IP66.
Protects against intrusion by solid objects (bodies smaller than dm12.5 mm).</p>
                </div>
                </div>
                <div onClick={() => {blog_function_form_block2()}} className="blog_div_button"><button>MORE</button></div>
            </div>
            <div className="blog_header_news_div_new">
                <div className="blog_div_1">
                <div className="news_div_img">
                    <img src={IMG6_BLOG} alt="" />
                </div>
                <div className="news_metaTeg">
                    <h1>How much can you earn by investing in a solar station?</h1>
                    <p>1. Environmentally friendly energy: solar energy is clean, renewable energy. It does not emit harmful gases into the atmosphere.

2. Infinite source of Energy: Solar Energy is considered almost infinite. 

3. Energy independence: solar stations reduce the country's dependence on energy imports.</p>
                </div>
                </div>
                <div onClick={() => {blog_function_form_block3()}} className="blog_div_button"><button>MORE</button></div>
            </div>
        </div>
        <Footer/>
    </div>}
   </div>
  )
}
