
import React, { useState } from 'react'
import "../css/Footer.css"

import IMG_F1 from "../img/uzbekistan-square-national-flag-vector.jpg"

import { FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

export default function Footer() {

  const [foterr_lan, setFoterLan] = useState(localStorage.getItem("lan"))

  return (
    <div>
      {foterr_lan == 0 ? <div className='footer'>
<div className="fot">
<div className="fot_1">
     <p>Узбекистонда қуёш панелларини расмий ўрнатиб берувчиси</p>
     <div className="img_div">
     <img src={IMG_F1} alt="" />
     </div>
 </div>
 <div className="fot_2">
     <p><a href="tel:+998 97 813 77 00">+998 97 813 77 00</a></p>
     <span>Маслаҳат олинг</span>
 </div>
 <div className="fot_3">
     <div className="fot_3_icon">
     <a href="https://www.youtube.com/@sunnurenergy"><FaYoutube className='footer_youtube_icon'/></a>
     </div>
     <div className="fot_3_icon">
    <a href="https://www.instagram.com/sunnur_energy_tech/"><AiFillInstagram className='footer_instagram_icon'/></a>
     </div>
     <div className="fot_3_icon">
     <a href="https://t.me/sunnur_energy"><FaTelegram /></a>
     </div>
     <div className="fot_3_icon">
     <a href="/contact"><FaLocationDot className='footer_loc_icon'/></a>
     </div>
 </div>
</div>
</div>:  <div className='footer'>
       <div className="fot">
       <div className="fot_1 fot_2222">
            <p>Official designer of bridal panels in <span>Uzbekistan</span></p>
            <div className="img_div img_div4">
            <img src={IMG_F1} alt="" />
            </div>
        </div>
        <div className="fot_2">
            <p><a href="tel:+998 97 813 77 00">+998 97 813 77 00</a></p>
            <span>Get advice</span>
        </div>
        <div className="fot_3">
            <div className="fot_3_icon">
            <a href="https://www.youtube.com/@sunnurenergy"><FaYoutube className='footer_youtube_icon'/></a>
            </div>
            <div className="fot_3_icon">
           <a href="https://www.instagram.com/sunnur_energy_tech/"><AiFillInstagram className='footer_instagram_icon'/></a>
            </div>
            <div className="fot_3_icon">
            <a href="https://t.me/sunnur_energy"><FaTelegram /></a>
            </div>
            <div className="fot_3_icon">
            <a href="/contact"><FaLocationDot className='footer_loc_icon'/></a>
            </div>
        </div>
       </div>
     </div>}
    </div>
    
  )
}
