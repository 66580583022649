import React , { useEffect, useState } from 'react'
import "../css/Kompaniya.css"
import Footer from './Footer'
import Navbar from './Navbar'

import IMG8 from "../img/KapitalBank_mini.png"
import IMG9 from "../img/AgroBank-mini-6414643a35289.jpg"
import IMG10 from "../img/d1958685481d015d1db382064ea84782.jpg"
import IMG11 from "../img/__26.jpg"
import IMG12 from "../img/5f2bf5eb38ac2915bc30fbd1_Acwapower.png"
import IMG13 from "../img/unnamed (1).jpg"
import IMG14 from "../img/6546854615646546515.jpg"
import IMG15 from "../img/Аннотация 2024-02-25 200414-fotor-bg-remover-20240225201058.png"
import IMG16 from "../img/adfbbd786df81829eee9ffbb167ef283.jpg"
import IMG17 from "../img/HQEbnFnpv7SYmr5cuc1z-49-a-5Q8-5g9FE5PMZGkerljj1FWzO6pbSH-5y1L4ql2_9CCpU4wYwRsUgGhXYaH750SdInu6dfIDAKQm3bM1ky_LPgYxaT0Op2epGHmBXgisaynHkJz0mdjKAQ9ZifLzU2BqeEX-LIt5K3tWe2iuZxqxVrH4Q2FXRRVO7q9uewDWdU-f.jpg"
import IMG_KOM1 from "../img/stylish-indian-young-man-sunglasses-wear-casual-posed-outdoor.jpg"
import IMG_KOM2 from "../img/young-hispanic-man-smiling-confident-standing-at-street.jpg"
import IMG_KOM3 from "../img/an-old-man-living-in-the-village-in-the-nature.jpg"
import IMG_NEW_IMG1 from "../img/logo-2628-632b0a0bd076c.png"

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

import { Autoplay, Navigation } from 'swiper/modules';
import { MdOutlineSms } from "react-icons/md";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Kompaniya() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


      const [companyLan, setComapnyLan] = useState(localStorage.getItem("lan"))
  return (
    <div>
        {companyLan == 0 ?  <div className='Kompaniya_div'>
        <Navbar/>

        <div className="product_header kompaniya_header">
          <h1>SUN<span>NUR</span> Ўзбекистондаги биринчи <d>тўлиқ</d> хизмат кўрсатувчи компания</h1>
          <p className='plus_p_kompaniya'>SUNNUR компанияси ташкил топганига жуда оз вақт ўтган бўлса-да, биз бутун Ўзбекистон бўйлаб 270 дан ортиқ катта ва кичик (1-100 мегаватт) қуёш станцияларини ўрнатдик!</p>
          <p className='none_p_kompaniya'>Биз бутун Ўзбекистон бўйлаб 270 дан ортиқ (1-100 мегаватт) қуёш станцияларини ўрнатдик!</p>
          <div data-aos="fade-up">
          <div className="product_header_button kompaniya_header_button">
            <a href="#brend_scroll"><button>БАТАФСИЛ</button></a>
          </div>
          <div className="product_header_span">
         <span>Биз ҳам ҳамкорлик қиламиз</span>
         </div>
          </div>
        </div>

        <div className="brend kompaniya_brend" id='brend_scroll'>
        <h1>Ўз лойихалари билан бизга<br /> ишонадиган компаниялар</h1>
        
        <div className="brend_1_div">
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG8} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"> <img src={IMG9} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG10} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG11} alt="" />  </div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border1"><img className='brend1_1_div_img' src={IMG13} alt="" /> </div>
          </div>
          <div className="div_img_border div_img_border_copiy2">
          <div data-aos="fade-right">
            <img src={IMG14} alt="" /></div>
          </div>
         
          </div>
              
          <div className="brend_1_div">
          <div className="div_img_border div_img_border_copiy"><div data-aos="fade-left"><img src={IMG14} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border "><img className='krg_foto' src={IMG15} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG12} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG16} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG17} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border div_img_border_NEW_1"><img src={IMG_NEW_IMG1} alt="" /></div></div>
          </div>
        </div>

        <div className="slider_kompaniya">
            <h1>Мижозларимизнинг фикрлари</h1>
            <div className="slider_K">
            <Swiper  centeredSlides={true} autoplay={{delay: 3500, disableOnInteraction: false,}} navigation={true} modules={[Navigation,Autoplay]} className="mySwiper">
        <SwiperSlide>
            <div className="div_slide">
                <div className="div_slide_1">
                    <img src={IMG_KOM2} alt="" />
                    <div className='div_PPPP'>
                        <p className='name_p_kompaniya'>Осарбоев Ойбек</p>
                    <p className='kompaniya_p_taminati'>"Мактаб" 341-мактабнинг директори</p>
                    </div>
                    <MdOutlineSms className='icon_kompaniya'/>
                </div>
                <div className="div_slide_2">
                    <span>Тошкент шахрида, кол остимдаги мактабга, қувватлаш стансияларини ўрнатишни ният қилган едик, бу мақсадимизда еришишда яқиндан кўмаклашган "Sunnur" компаниясига раҳмат.Хозирда электр бойича хеч кандай муаммо йук ва биз бундан хурсандмиз.</span>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="div_slide">
                <div className="div_slide_1">
                    <img src={IMG_KOM3} alt="" />
                    <div className='div_PPPP'>
                        <p className='name_p_kompaniya'>Холматов Отабек</p>
                    <p className='kompaniya_p_taminati'>"Махалла раиси" Камолот кишлоги</p>
                    </div>
                    <MdOutlineSms className='icon_kompaniya'/>
                </div>
                <div className="div_slide_2">
                    <span>Самарқанд вилоятининг, Камолот кишлогида электор токи кам болганлиги учун, хонадонларда муаммолар пайдо болишни бошлади, куйош панелларини орнатиш учун, "Sunnur" компаниясига мурожат килдик ва улар холатимизни тушунган холда бизга катта йордам беришди, озининг куйош стансиялари билан.</span>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="div_slide">
                <div className="div_slide_1">
                    <img src={IMG_KOM1} alt="" />
                    <div className='div_PPPP'>
                        <p className='name_p_kompaniya'>Алиев Жасур</p>
                    <p className='kompaniya_p_taminati'>"Ишонч" та'минот болими рахбари</p>
                    </div>
                    <MdOutlineSms className='icon_kompaniya'/>
                </div>
                <div className="div_slide_2">
                    <span>Самарқанд вилоятига кўпдан тезкор куйош стансияларини ўрнатишни ният қилган едик, бу мақсадимизда еришишда яқиндан кўмаклашган "Sunnur" компаниясига раҳмат.Келгусида Самарқанд шахрида куйош стансияларини янада копрок орнатишда биргаликда ишлашни давом еттирамиз.</span>
                </div>
            </div>
        </SwiperSlide>
      </Swiper>
            </div>
        </div>

        <Footer/>
    </div>  : <div className='Kompaniya_div'>
        <Navbar/>

        <div className="product_header kompaniya_header">
          <h1>SUN<span>NUR</span> is the first full-service company in Uzbekistan</h1>
          <p className='plus_p_kompaniya'>Although very little time has passed since the founding of the SUNNUR company, we have installed more than 270 large and small (1-100 megawatts) dowel panels all over Uzbekistan!</p>
          <p className='none_p_kompaniya'>We have installed more than 270 (1-100 megawatts) dowry panels all over Uzbekistan!</p>
          <div data-aos="fade-up">
          <div className="product_header_button kompaniya_header_button">
            <a href="#brend_scroll"><button>MORE</button></a>
          </div>
          <div className="product_header_span">
         <span>We also cooperate</span>
         </div>
          </div>
        </div>

        <div className="brend kompaniya_brend" id='brend_scroll'>
        <h1>To us with their mud trusted companies</h1>
        
        <div className="brend_1_div">
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG8} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"> <img src={IMG9} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG10} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG11} alt="" />  </div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border1"><img className='brend1_1_div_img' src={IMG13} alt="" /> </div>
          </div>
          <div className="div_img_border div_img_border_copiy2">
          <div data-aos="fade-right">
            <img src={IMG14} alt="" /></div>
          </div>
         
          </div>
              
          <div className="brend_1_div">
          <div className="div_img_border div_img_border_copiy"><div data-aos="fade-left"><img src={IMG14} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border "><img className='krg_foto' src={IMG15} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG12} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG16} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG17} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border div_img_border_NEW_1"><img src={IMG_NEW_IMG1} alt="" /></div></div>
          </div>
        </div>

        <div className="slider_kompaniya">
            <h1>Feedback from our customers</h1>
            <div className="slider_K">
            <Swiper  centeredSlides={true} autoplay={{delay: 3500, disableOnInteraction: false,}} navigation={true} modules={[Navigation,Autoplay]} className="mySwiper">
        <SwiperSlide>
            <div className="div_slide">
                <div className="div_slide_1 div_slide_12345">
                    <img src={IMG_KOM2} alt="" />
                    <div className='div_PPPP'>
                        <p className='name_p_kompaniya'>Osarboev Oybek</p>
                    <p className='kompaniya_p_taminati'>"Principal" school of 341</p>
                    </div>
                    <MdOutlineSms className='icon_kompaniya'/>
                </div>
                <div className="div_slide_2">
                    <span>In Tashkent, to the school in kol ostim, we intended to install support stations, thanks to the company "Sunnur", which closely helped us achieve this goal.Now the power boy Hech kanday problem load and we are happy about it.</span>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="div_slide">
                <div className="div_slide_1 div_slide_12345">
                    <img src={IMG_KOM3} alt="" />
                    <div className='div_PPPP'>
                        <p className='name_p_kompaniya name_p_kompaniya123'>Xolmatov Otabek</p>
                    <p className='kompaniya_p_taminati kompaniya_p_taminati23456'>"Neighborhood chairman" Kamolot village</p>
                    </div>
                    <MdOutlineSms className='icon_kompaniya'/>
                </div>
                <div className="div_slide_2">
                    <span>In the village of Kamolot, Samarkand region, problems began to arise in the apartments due to low power, Sunnur company kuesh stations an appeal for installation came and they understood our situation and helped us a lot at kuesh stations.</span>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="div_slide">
                <div className="div_slide_1 div_slide_12345">
                    <img src={IMG_KOM1} alt="" />
                    <div className='div_PPPP'>
                        <p className='name_p_kompaniya name_p_kompaniya123'>Aliyev Jasur</p>
                    <p className='kompaniya_p_taminati kompaniya_p_taminati23456'>Master of a reliable supply unit</p>
                    </div>
                    <MdOutlineSms className='icon_kompaniya'/>
                </div>
                <div className="div_slide_2">
                    <span>We intended to install many fast kuyosh stations in the Samarkand region, thanks to the company "Sunnur", which closely helped us achieve this goal.In the future, we will continue to work together in the further installation of groom stations in Samarkand City.</span>
                </div>
            </div>
        </SwiperSlide>
      </Swiper>
            </div>
        </div>

        <Footer/>
    </div>}
    </div>
  )
}
