import React, { useEffect, useState } from 'react'
import "../css/Form_input.css"

import AOS from "aos";
import "aos/dist/aos.css";

import { PiCaretRightBold } from "react-icons/pi";

export default function Form_input() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  function bot1(){
    var testButtonBot = false
    var input_name = document.querySelector("#input_name_id").value
    var input_phone = document.querySelector("#input_phone_id").value
    var token = "6714751698:AAHz41ya68z95WgWPbSfXH2Ywi7isE6zzqo"
    var chat_id = -4158475573
    if( (input_name.length > 1) && (input_phone.length > 6) ) { 
    var chat = `Маҳсулот номи: ${input_name}%0AТелефон рақами: ${"%2B"}${input_phone}`
    fetch(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${chat}`)
    testButtonBot = true
    if(testButtonBot == true){
      document.querySelector("#input_name_id").style = "border: 1px solid rgb(146, 146, 146);"
      document.querySelector("#input_phone_id").style = "border: 1px solid rgb(146, 146, 146);"
      for (let i = 0; i < document.querySelectorAll('.position_span_form_cop').length; i++) {
        document.querySelectorAll(".position_span_form_cop")[i].style = "display:none"
        }
      document.querySelector("#input_name_id").value = ""
      document.querySelector("#input_phone_id").value = ""
      document.querySelector(".zayavk_ok_200_cop").style = "display: flex;"
      setTimeout(function (){
        document.querySelector(".zayavk_ok_200_cop").style = "display: none;"
      },10000)
    }
    }
    else{
      if(!(input_name.length > 1)){
      document.querySelector("#input_name_id").style = "border: 1px solid red;"
      for (let i = 0; i < document.querySelectorAll('.position_span_form_cop').length; i++) {
        document.querySelectorAll(".position_span_form_cop")[0].style = "display:block"
        }
      }
      else if(!(input_phone.length > 8)){
        document.querySelector("#input_phone_id").style = "border: 1px solid red;"
        for (let i = 0; i < document.querySelectorAll('.position_span_form_cop').length; i++) {
          document.querySelectorAll(".position_span_form_cop")[1].style = "display:block"
          }
      }
      else{
        document.querySelector("#input_name_id").style = "border: 1px solid red;"
        document.querySelector("#input_phone_id").style = "border: 1px solid red;"
      for (let i = 0; i < document.querySelectorAll('.position_span_form_cop').length; i++) {
        document.querySelectorAll(".position_span_form_cop")[i].style = "display:block"
        }
      }
      

      testButtonBot = false
    }
    }

    const [language_form, setlanguageForm] = useState(localStorage.getItem("lan"))
  return (
    <div>
      {language_form == 0 ? <div className="zayavka">
<div className="zayavk_1">
  <h1>Саволингизни тўғридан тўғри мутахассисга беринг</h1>
  <p>Сиз учун тўгри қуёш станциясини қараб беришда кўмаклашамиз,<span> саволларингиз болса бизнинг мутахассисга богланинг.</span></p>
  <div data-aos="fade-right">
  <div className="line_right"><PiCaretRightBold className='line_icon'/></div>
  </div>
</div>

<div className="zayavk_2">
<div data-aos="fade-up">
<p>Қуйидаги сўровномани тўлдиринг ва биз сиз билан богланамиз</p>
  <div className="zayavk_input">
    <input id='input_name_id' className='input_name' type="text" placeholder='Маҳсулот номи'/>
    <span className='position_span_form position_span_form_cop'>Малумотни толиқ киритинг</span>
  </div>
  <div className="zayavk_input">
    <input id='input_phone_id' className='input_phone' type="number" placeholder='99-999-9999'/>
    <span className='position_span_form position_span_form_cop'>Малумотни толиқ киритинг</span>
  </div>

  <div className="zayavk_button">
    <button type='submit' onClick={() => {bot1()}}>МАСЛАҲАТ ОЛИНГ</button>
    <div className='zayavk_ok_200 zayavk_ok_200_cop'><span>БИЗ СИЗ БИЛАН БОГЛАНАМИЗ!</span></div>
  </div>
</div>
</div>
</div>
: <div className="zayavka">
          <div className="zayavk_1">
            <h1>Ask your question directly to a specialist</h1>
            <p>For you, we will make a comatose guest station in the carabiner,<span> if you have questions, contact our specialist.</span></p>
            <div data-aos="fade-right">
            <div className="line_right"><PiCaretRightBold className='line_icon'/></div>
            </div>
          </div>

          <div className="zayavk_2">
          <div data-aos="fade-up">
          <p>Fill in the following directory and we will contact you</p>
            <div className="zayavk_input">
              <input id='input_name_id' minLength={0} maxLength={15} className='input_name' type="text" placeholder='Product name'/>
              <span className='position_span_form position_span_form_cop'>Enter information</span>
            </div>
            <div className="zayavk_input">
              <input id='input_phone_id' className='input_phone' type="number" placeholder='99-999-9999'/>
              <span className='position_span_form position_span_form_cop'>Enter information</span>
            </div>
            <div className="zayavk_button">
              <button type='submit' onClick={() => {bot1()}}>GET ADVICE</button>
              <div className='zayavk_ok_200 zayavk_ok_200_cop'><span>WE CONNECT WITH YOU!</span></div>
            </div>
          </div>
          </div>
        </div>}
         
    </div>
  )
}
