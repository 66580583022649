import React from 'react'
import { BrowserRouter ,Route, Routes, } from 'react-router-dom'
import Glava from "./js/SolarEnergy"
import Product from "./js/Product"
import FormInput from "./js/Form_input"
import Proect from "./js/Proect"
import Kompaniya from './js/Kompaniya'
import Contact from './js/Contact'
import Blog from './js/Blog'

export default function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Glava/>}/>
        <Route path='/product' element={<Product/>}/>
        <Route path='/proect' element={<Proect/>}/>
        <Route path='/company' element={<Kompaniya/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/bloc' element={<Blog/>}/>
        <Route path='/form' element={<FormInput/>}/>
      </Routes>
    </BrowserRouter>
  )
}
