
import React, { useEffect, useState } from 'react'
import Footer from './Footer';
import Navbar from './Navbar';
import "../css/SolarEnergy.css"

import AOS from "aos";
import "aos/dist/aos.css";

import IMG_NEW_IMG1 from "../img/logo-2628-632b0a0bd076c.png"

import IMG5 from "../img/e24d905265 1.png"
import IMG8 from "../img/KapitalBank_mini.png"
import IMG9 from "../img/AgroBank-mini-6414643a35289.jpg"
import IMG10 from "../img/d1958685481d015d1db382064ea84782.jpg"
import IMG11 from "../img/__26.jpg"
import IMG12 from "../img/5f2bf5eb38ac2915bc30fbd1_Acwapower.png"
import IMG13 from "../img/unnamed (1).jpg"
import IMG14 from "../img/6546854615646546515.jpg"
import IMG15 from "../img/Аннотация 2024-02-25 200414-fotor-bg-remover-20240225201058.png"
import IMG16 from "../img/adfbbd786df81829eee9ffbb167ef283.jpg"
import IMG17 from "../img/HQEbnFnpv7SYmr5cuc1z-49-a-5Q8-5g9FE5PMZGkerljj1FWzO6pbSH-5y1L4ql2_9CCpU4wYwRsUgGhXYaH750SdInu6dfIDAKQm3bM1ky_LPgYxaT0Op2epGHmBXgisaynHkJz0mdjKAQ9ZifLzU2BqeEX-LIt5K3tWe2iuZxqxVrH4Q2FXRRVO7q9uewDWdU-f.jpg"
import IMG18 from "../img/photo_2024-02-08_11-48-27.jpg"
import IMG19 from "../img/dah-solar-logo-vector.png"
import IMG20 from "../img/logo-Jinko.png"
import IMG21 from "../img/Will-Trina-Solar-Limited.png"
import IMG22 from "../img/longi_green_energy_technology-logo_brandlogos.net_s56hx.png"
import IMG23 from "../img/ja-solar.jpg"
import IMG24 from "../img/canadian-solar-logo.jpg"
import IMG25 from "../img/logo.jpg"
import IMG26 from "../img/logo_dmegc_batteryt.jpg"
import IMG27 from "../img/SunTech-Company-Logo.jpg"
import IMG28 from "../img/unnamed.jpg"
import IMG29 from "../img/image-67.jpg"
import IMG30 from "../img/Совместное-предприятие-создали-GCL-System-Integration-и-Powin-Energy.jpeg"
import IMG31 from "../img/arista-square 1.png"

import { PiHandCoins } from "react-icons/pi";
import { IoIosStar } from "react-icons/io";
import { FaSolarPanel } from "react-icons/fa";
import { FaAward } from "react-icons/fa";
import { PiCertificateFill } from "react-icons/pi";
import { IoDocument } from "react-icons/io5";
import { FaArrowUpWideShort } from "react-icons/fa6";
import { GiProgression } from "react-icons/gi";
import { IoSettingsSharp } from "react-icons/io5";
import { MdClear } from "react-icons/md";
import Form_input from './Form_input';

export default function SolarEnergy() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  
  function bot(){
    var testButtonBot = false
    var input_name = document.querySelector(".input_name").value
    var input_phone = document.querySelector(".input_phone").value
    var token = "6714751698:AAHz41ya68z95WgWPbSfXH2Ywi7isE6zzqo"
    var chat_id = -4158475573
    if( (input_name.length > 1) && (input_phone.length > 6) ) { 
    var chat = `Маҳсулот номи: ${input_name}%0AТелефон рақами: ${"%2B"}${input_phone}`
    fetch(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${chat}`)
    testButtonBot = true
    if(testButtonBot == true){
      document.querySelector(".input_name").style = "border: 1px solid rgb(146, 146, 146);"
      document.querySelector(".input_phone").style = "border: 1px solid rgb(146, 146, 146);"
      for (let i = 0; i < document.querySelectorAll('.position_span_form').length; i++) {
        document.querySelectorAll(".position_span_form")[i].style = "display:none"
        }
      document.querySelector(".input_name").value = ""
      document.querySelector(".input_phone").value = ""
      document.querySelector(".zayavk_ok_200").style = "display: flex;"
      setTimeout(function (){
        document.querySelector(".zayavk_ok_200").style = "display: none;"
        function_form_header_none()
      },8000)
    }
    }
    else{
      if(!(input_name.length > 1)){
      document.querySelector(".input_name").style = "border: 1px solid red;"
      for (let i = 0; i < document.querySelectorAll('.position_span_form').length; i++) {
        document.querySelectorAll(".position_span_form")[0].style = "display:block"
        }
      }
      else if(!(input_phone.length > 8)){
        document.querySelector(".input_phone").style = "border: 1px solid red;"
        for (let i = 0; i < document.querySelectorAll('.position_span_form').length; i++) {
          document.querySelectorAll(".position_span_form")[1].style = "display:block"
          }
      }
      else{
        document.querySelector(".input_name").style = "border: 1px solid red;"
        document.querySelector(".input_phone").style = "border: 1px solid red;"
      for (let i = 0; i < document.querySelectorAll('.position_span_form').length; i++) {
        document.querySelectorAll(".position_span_form")[i].style = "display:block"
        }
      }
      

      testButtonBot = false
    }
    }
    function function_form_header(){
      window.onscroll = function () { window.scrollTo(1, 1); };
      document.querySelector(".form_header_to_bot").style = "z-index: 1000; opacity: 1; transform: rotateY(0deg);"
    }
    function function_form_header_none(){
      window.onscroll = function () {};
      document.querySelector(".form_header_to_bot").style = "z-index: -1000; opacity: 0; transform: rotateY(180deg);"
    }

    const [data_product, setDataProduct] = useState([
      {
        img: IMG18,
        name: `Қуватлаш панеллари 5 квт (АC)`,
        title: "Йилига ток ишлаб чиқариш 9,125 квт",
      },
      {
        img: IMG18,
        name: "Қуватлаш панеллари 10 квт (АC)",
        title: "Йилига ток ишлаб чиқариш 18,250 квт",
      },
      {
        img: IMG18,
        name: "Қуватлаш панеллари 15 квт (АC)",
        title: "Йилига ток ишлаб чиқариш 27,375 квт",
      },
      {
        img: IMG18,
        name: "Қуватлаш панеллари 30 квт (АC)",
        title: "Йилига ток ишлаб чиқариш 54,750 квт",
      },
      {
        img: IMG18,
        name: "Қуватлаш панеллари 50 квт (АC)",
        title: "Йилига ток ишлаб чиқариш 91,250 квт",
      },
      {
        img: IMG18,
        name: "Қуватлаш панеллари 100 квт (АC)",
        title: "Йилига ток ишлаб чиқариш 182,500 квт",
      },
    ])
  

    
    var data_product_cop_cop = [
      {
        img: IMG18,
        name_two: "5 квт (АC)",      
        name: `Қуватлаш панеллари 5 квт (АC)`,
        title: "Йилига ток ишлаб чиқариш 9,125 квт",
        productInfo: {
          fullProductName: "Solar panel Longi /Jinkoa 550-565M ",
          fullProductType: "Inventor Solax 5 kW",
          fullProductType_2: "Профил (4mm* 2,5mm)",
          fullProductKabel: "Кабел 1*4 мм",
          fullProductEnergy: {
           energy_1: "Бир кунлик ишлаб чиқариш: 5кw * 7 соат = 35kw",
           energy_2: "Бир ойлик ишлаб чиқариш: 35kw * 30 кун = 1050kw"
          } ,
          fullProductAutoRegiretion: "Умумий пул микдори = 1050kw / 2 = 5250kw."
        }
      },
      {
        img: IMG18,
        name_two: "10 квт (АC)",      
        name: "Қуватлаш панеллари 10 квт (АC)",
        title: "Йилига ток ишлаб чиқариш 18,250 квт",
        productInfo: {
          fullProductName: "Solar panel Longi / Jinkoa 550-565M",
          fullProductType: "Inventor Solax 10 kW",
          fullProductType_2: "Профил (4mm* 2,5mm)",
          fullProductKabel: "Кабел 1*4 мм",
          fullProductEnergy: {
            energy_1: "Бир кунлик ишлаб чиқариш: 10кw * 7 соат = 70kw",
            energy_2: "Бир ойлик ишлаб чиқариш: 70kw * 30 кун = 2100kw"
           } ,
          fullProductAutoRegiretion: "Умумий пул микдори = 2100kw / 2 = 1050kw."
        }
      },
      {
        img: IMG18,
        name_two: "15 квт (АC)",      
        name: "Қуватлаш панеллари 15 квт (АC)",
        title: "Йилига ток ишлаб чиқариш 27,375 квт",
        productInfo: {
          fullProductName: "Solar panel Longi / Jinkoa 550-565M",
          fullProductType: "Inventor Solax 15 kW",
          fullProductType_2: "Профил (4mm* 2,5mm)",
          fullProductKabel: "Кабел 1*4 мм",
          fullProductEnergy: {
            energy_1: "Бир кунлик ишлаб чиқариш: 15кw * 7 соат = 105kw",
            energy_2: "Бир ойлик ишлаб чиқариш: 15кw * 7 соат = 105kw"
           } ,
          fullProductAutoRegiretion: "Умумий пул микдори = 3150kw / 2 = 1575kw."
        }
      },
      {
        img: IMG18,
        name_two: "30 квт (АC)",      
        name: "Қуватлаш панеллари 30 квт (АC)",
        title: "Йилига ток ишлаб чиқариш 54,750 квт",
        productInfo: {
          fullProductName: "Solar panel Longi / Jinkoa 550-565M",
          fullProductType: "Inventor Solax 30 kW",
          fullProductType_2: "Профил (4mm* 2,5mm)",
          fullProductKabel: "Кабел 1*4 мм",
          fullProductEnergy: {
            energy_1: "Бир кунлик ишлаб чиқариш: 30кw * 7 соат = 210kw",
            energy_2: "Бир ойлик ишлаб чиқариш: 210kw * 30 кун = 6300kw"
           } ,
          fullProductAutoRegiretion: "Умумий пул микдори = 6300kw / 2 = 3150kw."
        }
      },
      {
        img: IMG18,
        name_two: "50 квт (АC)",      
        name: "Қуватлаш панеллари 50 квт (АC)",
        title: "Йилига ток ишлаб чиқариш 91,250 квт",
        productInfo: {
          fullProductName: "Solar panel Longi / Jinkoa 550-565M",
          fullProductType: "Inventor Solax 50 kW",
          fullProductType_2: "Профил (4mm* 2,5mm)",
          fullProductKabel: "Кабел 1*4 мм",
          fullProductEnergy: {
            energy_1: "Бир кунлик ишлаб чиқариш: 50кw * 7 соат = 350kw",
            energy_2: "Бир ойлик ишлаб чиқариш: 350kw * 30 кун = 10,500kw"
           } ,
          fullProductAutoRegiretion: "Умумий пул микдори = 6300kw / 2 = 3150kw."
        }
      },
      {
        img: IMG18,
        name_two: "100 квт (АC)",      
        name: "Қуватлаш панеллари 100 квт (АC)",
        title: "Йилига ток ишлаб чиқариш 182,500 квт",
        productInfo: {
          fullProductName: "Solar panel Longi / Jinkoa 550-565M",
          fullProductType: "Inventor Solax 100 kW",
          fullProductType_2: "Профил (4mm* 2,5mm)",
          fullProductKabel: "Кабел 1*4 мм",
          fullProductEnergy: {
            energy_1: "Бир кунлик ишлаб чиқариш: 100кw * 7 соат = 700kw",
            energy_2: "Бир ойлик ишлаб чиқариш: 700kw * 30 кун = 21,000kw"
           } ,
          fullProductAutoRegiretion: "Умумий пул микдори = 6300kw / 2 = 3150kw."
        }
      }
    ] 
    var data_product_cop_cop_ENG = [
      {
        img: IMG18,
        name_two: "5 kW (АC)",      
        name: `Power panels 5 kW (AC)`,
        title: "Current production per year 9,125 kW",
        productInfo: {
          fullProductName: "Solar panel Longi /Jinkoa 550-565M ",
          fullProductType: "Inventor Solax 5 kW",
          fullProductType_2: "Profile (4mm * 2,5mm)",
          fullProductKabel: "Cable 1*4 mm",
          fullProductEnergy: {
           energy_1: "One-day production: 5kW * 7 hours = 35kW",
           energy_2: "One month production: 35kw * 30 days = 1050kw"
          } ,
          fullProductAutoRegiretion: "Total Money mix = 1050kw / 2 = 5250kw."
        }
      },
      {
        img: IMG18,
        name_two: "10 kW (АC)",      
        name: "Power panels 10 kW (АC)",
        title: "Current production per year 18,250 kW",
        productInfo: {
          fullProductName: "Solar panel Longi / Jinkoa 550-565M",
          fullProductType: "Inventor Solax 10 kW",
          fullProductType_2: "Profile (4mm * 2,5mm)",
          fullProductKabel: "Cable 1*4 mm",
          fullProductEnergy: {
            energy_1: "One-day production: 10kw * 7 hours = 70kW",
            energy_2: "One month production: 70kw * 30 days = 2100kw"
           } ,
          fullProductAutoRegiretion: "Total Money mix = 2100kw / 2 = 1050kw."
        }
      },
      {
        img: IMG18,
        name_two: "15 kW (АC)",      
        name: "Power panels 15 kW (АC)",
        title: "Current production per year 27,375 kW",
        productInfo: {
          fullProductName: "Solar panel Longi / Jinkoa 550-565M",
          fullProductType: "Inventor Solax 15 kW",
          fullProductType_2: "Profile (4mm * 2,5mm)",
          fullProductKabel: "Cable 1*4 mm",
          fullProductEnergy: {
            energy_1: "One-day production: 15kW * 7 hours = 105kW",
            energy_2: "One month production: 15kW * 7 hours = 105kW"
           } ,
          fullProductAutoRegiretion: "Total Money mix = 3150kw / 2 = 1575kw."
        }
      },
      {
        img: IMG18,
        name_two: "30 kW (АC)",      
        name: "Power panels 30 kW (АC)",
        title: "Current production per year 54,750 kW",
        productInfo: {
          fullProductName: "Solar panel Longi / Jinkoa 550-565M",
          fullProductType: "Inventor Solax 30 kW",
          fullProductType_2: "Profile (4mm * 2,5mm)",
          fullProductKabel: "Cable 1*4 mm",
          fullProductEnergy: {
            energy_1: "One-day production: 30KW * 7 hours = 210kW",
            energy_2: "One month production: 210kW * 30 days = 6300kw"
           } ,
          fullProductAutoRegiretion: "Total Money mix = 6300kw / 2 = 3150kw."
        }
      },
      {
        img: IMG18,
        name_two: "50 kW (АC)",      
        name: "Power panels 50 kW (АC)",
        title: "Current production per year 91,250 kW",
        productInfo: {
          fullProductName: "Solar panel Longi / Jinkoa 550-565M",
          fullProductType: "Inventor Solax 50 kW",
          fullProductType_2: "Profile (4mm * 2,5mm)",
          fullProductKabel: "Cable 1*4 mm",
          fullProductEnergy: {
            energy_1: "One-day production: 50KW * 7 hours = 350kW",
            energy_2: "One month production: 350kW * 30 days = 10,500kw"
           } ,
          fullProductAutoRegiretion: "Total Money mix = 6300kw / 2 = 3150kw."
        }
      },
      {
        img: IMG18,
        name_two: "100 kW (АC)",      
        name: "Power panels 100 kW (АC)",
        title: "Current production per year 182,500 kW",
        productInfo: {
          fullProductName: "Solar panel Longi / Jinkoa 550-565M",
          fullProductType: "Inventor Solax 100 kW",
          fullProductType_2: "Profile (4mm * 2,5mm)",
          fullProductKabel: "Cable 1*4 mm",
          fullProductEnergy: {
            energy_1: "One-day production: 100KW * 7 hours = 700kW",
            energy_2: "One month production: 700kW * 30 days = 21,000kw"
           } ,
          fullProductAutoRegiretion: "Total Money mix = 6300kw / 2 = 3150kw."
        }
      },
    ] 
    const [element_1, setElement_1] = useState(0)
    function productFunctionForm_Cop(key){
      setElement_1(key)
      document.querySelector('body').style = "overflow-y: hidden"
      document.querySelector(".productFormDesc").style = "z-index: 100; opacity: 1;"
    }
    function noneFormProduct(){
      document.querySelector(".productFormDesc").style = "z-index: -100; opacity: 0;"
      document.querySelector('body').style = "overflow-y: auto"
    }  
    const [glavaLanguage, setGlavaLanguage] = useState(localStorage.getItem("lan"))
  return (
   <div>
    {glavaLanguage == 0 ? <div className='MAIN_div'>
      <div className="form_header_to_bot">
      <div className="form_help_div ">
      <MdClear onClick={() => {function_form_header_none()}} className="none_form_div_x" />
      <div className="zayavk_2 zayavk_p_div zayavk_2_cop_cop">
            <p>Қуйидаги соровномани толдиринг ва биз сиз билан богланамиз</p>
            <div className="zayavk_input">
              <input className='input_name' type="text" placeholder='Маҳсулот номи'/>
              <span className='position_span_form'>Малумотни толиқ киритинг</span>
            </div>  
            <div className="zayavk_input">
              <input  className='input_phone' type="number" placeholder='99-999-9999'/>
              <span className='position_span_form'>Малумотни толиқ киритинг</span>
            </div>
            <div className="zayavk_button">
              <button type='submit' onClick={() => {bot()}}>МАСЛАҲАТ ОЛИНГ</button>
              <div className='zayavk_ok_200'><span>БИЗ СИЗ БИЛАН БОГЛАНАМИЗ!</span></div>
            </div>
          </div>
      </div>
      </div>
      <Navbar />
      <div className="productFormDesc">
        <MdClear onClick={() => {noneFormProduct()}} className='icon_product_form'/>
        
        <div className="productIdForm">
          {element_1 == 0 ? 
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop[0].img} alt="" />
                <h3>{data_product_cop_cop[0].name_two}</h3>
                <p>{data_product_cop_cop[0].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop[0].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Толиқ номи</h4>
                    <p>{data_product_cop_cop[0].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Профил</h4>
                    <p>{data_product_cop_cop[0].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Кабел</h4>
                    <p>{data_product_cop_cop[0].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Ишлаб чиқариш</h4>
                  <p>{data_product_cop_cop[0].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop[0].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                
              </div>
            </div>
        
          : element_1 == 1 ? 
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop[1].img} alt="" />
                <h3>{data_product_cop_cop[1].name_two}</h3>
                <p>{data_product_cop_cop[1].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop[1].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Толиқ номи</h4>
                    <p>{data_product_cop_cop[1].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Профил</h4>
                    <p>{data_product_cop_cop[1].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Кабел</h4>
                    <p>{data_product_cop_cop[1].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Ишлаб чиқариш</h4>
                  <p>{data_product_cop_cop[1].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop[1].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                
              </div>
            </div>
        
          : element_1 == 2 ? 
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop[2].img} alt="" />
                <h3>{data_product_cop_cop[2].name_two}</h3>
                <p>{data_product_cop_cop[2].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop[2].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Толиқ номи</h4>
                    <p>{data_product_cop_cop[2].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Профил</h4>
                    <p>{data_product_cop_cop[2].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Кабел</h4>
                    <p>{data_product_cop_cop[2].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Ишлаб чиқариш</h4>
                  <p>{data_product_cop_cop[2].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop[2].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                
              </div>
            </div>
         
          : element_1 == 3 ?
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop[3].img} alt="" />
                <h3>{data_product_cop_cop[3].name_two}</h3>
                <p>{data_product_cop_cop[3].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop[3].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Толиқ номи</h4>
                    <p>{data_product_cop_cop[3].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Профил</h4>
                    <p>{data_product_cop_cop[3].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Кабел</h4>
                    <p>{data_product_cop_cop[3].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Ишлаб чиқариш</h4>
                  <p>{data_product_cop_cop[3].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop[3].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                
              </div>
            </div>
       
          : element_1 == 4 ?
          <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop[4].img} alt="" />
                <h3>{data_product_cop_cop[4].name_two}</h3>
                <p>{data_product_cop_cop[4].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop[4].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Толиқ номи</h4>
                    <p>{data_product_cop_cop[4].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Профил</h4>
                    <p>{data_product_cop_cop[4].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Кабел</h4>
                    <p>{data_product_cop_cop[4].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Ишлаб чиқариш</h4>
                  <p>{data_product_cop_cop[4].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop[4].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                
              </div>
            </div>
            : element_1 == 5 ?
            <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop[5].img} alt="" />
                <h3>{data_product_cop_cop[5].name_two}</h3>
                <p>{data_product_cop_cop[5].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop[5].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Толиқ номи</h4>
                    <p>{data_product_cop_cop[5].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Профил</h4>
                    <p>{data_product_cop_cop[5].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Кабел</h4>
                    <p>{data_product_cop_cop[5].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Ишлаб чиқариш</h4>
                  <p>{data_product_cop_cop[5].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop[5].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                
              </div>
            </div>
            : ""
                 }
        </div>
        </div>
      <header className='header_1'>
        <div className="div_header_absolute">
        </div>
        <div className="div_header_h1">
        <div data-aos="fade-right"> <h1>Қуёш станцияларини сотиш ва ўрнатиш бўйича Ўзбекистонда юқори бўлган сифатли станциялар.</h1></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_header_span">
          <span>Харажатларни камайтирадиган ва атроф муҳитга ёрдам берадиган амалий қайта тикланадиган енергия технологияси</span>
          </div></div>
          <div data-aos="fade-right">
          <div className='del_button'>
          <div className="div_header_button">
            <button onClick={() => {function_form_header()}}>Ариза бериш</button>
          </div>
          </div>
          </div>
      </header>

      <div className="card">
      <div data-aos="fade-up">
        <div className="card_1">
          <div className="card_div1">
          <IoSettingsSharp className='icon_card_glava'/>
            <div>
            <h2>Тўлиқ шакилда ўрнатиш</h2>
            </div>
          </div>
          <div className="card_div2">
            <p>Биз етказиб бериш, ўрнатиш ва <span>техник хизмат</span> кўрсатиш бўйича <span>тўлиқ хизматларни</span> тақдим етамиз.</p>
          </div>
        </div>
      </div>
      <div data-aos="fade-up">
        <div className="card_1">
          <div className="card_div1">
          <PiHandCoins className='icon_card_glava icon_card_glava_cop'/>
            <div>
            <h2>Ўрнатиб бериш хизмати</h2>
            </div>
          </div>
          <div className="card_div2 card_div2_cop_1">
            <p>Бутун <span>Ўзбекистон</span> бўйлаб <span>ўрнатиш</span> ва <span>етказиб</span> бериш</p>
          </div>
        </div>
        </div>
        <div data-aos="fade-up">
        <div className="card_1">
          <div className="card_div1">
          <PiCertificateFill className='icon_card_glava icon_card_glava_cop1'/>
            <div>
          
          <h2>Ўзбекистон бозордаги ягона таклиф</h2>
            </div>
          </div>
          <div className="card_div2 card_div2_cop">
            <p>Қуёш станциялар учун <span>муддатли толов</span> мавжуд.</p>
          </div>
        </div>
        </div>
      </div>
     
      <div className="glavaElectroStansiy">
        <div className="glavaElectro_teg"></div>
        <div className="electro_1">
          <h1>Биз <span>Ўзбекистон бўйлаб 700 дан</span> ортик станция ўрнатдик</h1>
          </div>  
        <div className="big_electro">
            <div className="electro_2">
          <div data-aos="fade-right">
          <div className="electro_2_block">
              <div className="electro_2_block_div">
                <h2>700 дан ортик</h2>
                <div className="icon_div"><IoIosStar /></div>
              </div>
              <p className='p_big1'><span>Мижозларга ўрнатдик</span></p>
            </div>  
          </div>
        <div data-aos="fade-right">
            <div className="electro_2_block">
              <div className="electro_2_block_div">
                <h2>12 Вилоятга</h2>
                <div className="icon_div"><IoIosStar /></div>
              </div>
              <p className='p_big'><span>Вилоятларга хизмат</span> кўрсатамиз</p>
            </div>  
            </div>
        <div data-aos="fade-right">
        <div className="electro_2_block electro_2_block111">
              <div className="electro_2_block_div">
                <h2>Профисионал жамоа</h2>
                <div className="icon_div"><IoIosStar /></div>
              </div>
              <p className='p_big_ver'>Юқори даражадаги мураккаб ва <span>катта ҳажмга ега лойихалар билан ишлаш тажрибаси</span></p>
            </div>  
        </div>
          </div>  
          <div className="electro_3">
          <div data-aos="fade-left"><img className='electro_3_img_1' src={IMG5} alt="" /></div>
          </div> 
        </div> 
      </div>    

      <div className="brend">
        <h1>Бизга ишонч <span>билдирган брендлар</span></h1>
        
          <div className="brend_1_div">
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG8} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"> <img src={IMG9} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG10} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG11} alt="" />  </div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border1"><img className='brend1_1_div_img' src={IMG13} alt="" /> </div>
          </div>
          <div className="div_img_border div_img_border_copiy2">
          <div data-aos="fade-right">
            <img src={IMG14} alt="" /></div>
          </div>
         
          </div>
              
          <div className="brend_1_div">
          <div className="div_img_border div_img_border_copiy"><div data-aos="fade-left"><img src={IMG14} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border "><img className='krg_foto' src={IMG15} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG12} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG16} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG17} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border div_img_border_NEW_1"><img src={IMG_NEW_IMG1} alt="" /></div></div>
          </div>
        </div>

        <div className="yearElectro">
          <h1 className='year_h1'>Ўзбекистонда <span>2023-йилда 1,7 минг</span> мегаваттли қуёш<span> станциялари</span> ўрнатилади.</h1>
          <p className='year_p'>Ўзбекистонда електромобиллар <span>бозори ривожланмокда</span></p>
          <div className="yearCard">
          <div data-aos="fade-right">
          <div className="yaerCard_1">
              <h1>$427,1 млн</h1>
              <p>Ўзбекистон қуёш панеллари импортини <span>20 баробарга оширди</span> Январ-октабр ойларида хориждан жами $427,1 млнлик <span>3,85 млнта қуёш панеллари олиб келинган.</span></p>
              
              <div className='card_teg_year'>
                <div className="yearCard_number">
                <span>1</span>
              </div>
              </div>

            </div>
          </div>
          <div data-aos="fade-down">
          <div className="yaerCard_1">
              <h1>3 791,363 дона</h1>
              <p>21 та <span>мамлакатдан</span> жами <span>3 млн 791 минг 363та</span> қуёш панели олиб келинган.</p>
              <div className='card_teg_year2'>
                <div className="yearCard_number">
                <span>2</span>
              </div>
              </div>
              
            </div>
          </div>
           
            <div data-aos="fade-left">
            <div className="yaerCard_1">
              <h1>1,700 минг</h1>
              <p>2023-йилда тадбиркорлар, ижтимоий соҳа ва аҳоли хонадонларида <span>1,7 минг</span> мегаватт қуёш панеллари ўрнатилди.</p>
              <div className='card_teg_year3'>
              <div className="yearCard_number">
                <span>3</span>
              </div>
              </div>
            </div>
            </div>
            
          </div>
        </div>

        <div className="product">
          <h1>Бизнинг <span>махсулотлар</span></h1>
          <div className="product_div">
            {data_product.map((item,key) => {
              return <div>
              <div data-aos="fade-up"><div className="product_div_1">
              <div className="product_img"><img src={item.img} alt="" /></div>
              <h3>{item.name}</h3>
              <p>{item.title}</p>
              <div onClick={() => {productFunctionForm_Cop(key)}} className="product_button"><button>ТОЛИҚ МАЛУМОТ</button></div>
            </div></div>
              </div>
            })}  
          </div>
        </div>

        <div className="popul">
          <h1>Қуёш <span>станцияси</span> брендлари</h1>
          <div className="popul_div">
            <div className="popul_div_1">
              <img className='img_px' src={IMG19} alt="" />
              <img className='img_px' src={IMG20} alt="" />
              <img src={IMG21} alt="" />
              <img className='img_logn_px' src={IMG22} alt="" />
              <img className='img_short_px' src={IMG23} alt="" />
              <img className='img_logn_px1' src={IMG24} alt="" />
            </div>
            <div className="popul_div_1">
            <img className='img_px' src={IMG25} alt="" />
            <img className='img_px' src={IMG26} alt="" />
            <img src={IMG29} alt="" />
            <img className='img_logn_px' src={IMG28} alt="" />
            <img className='img_px' src={IMG27} alt="" />
            <img className='img_px2 ' src={IMG30} alt="" />
            </div>
          </div>
        </div>

        <div className="home">
         
          <h1>Қуёш Станцияларни <span>бизнес ва ахоли ехтиёжлари учун</span> ишлатилиш мумкин.</h1>
          <div className="home_div">

            <div className="home_div_1">
            <div data-aos="fade-up"><div className="home_div_tegi home_div_tegi_3">
                <h3>Бизнес марказлар</h3>
                <p>Ходимлар иш куни давомида керакли қурилмани заряд қилишлари мумкин</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
              
            </div>
           <div className="home_div_tegi home_div_tegi_2"> <div data-aos="fade-up">
                <h3>Бизнес марказлар</h3>
                <p>Ходимлар иш куни давомида керакли қурилмани заряд қилишлари мумкин</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
            
            <div className="home_div_2">
            <div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
                <h3>Меҳмонхона ва ресторан</h3>
                <p>Қуёш панеллари ташриф буюрувчилар сонини кўпайтиради ва муассаса обрўсини оширади</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
             
            </div>
            <div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up"> 
                <h3>Меҳмонхона ва ресторан</h3>
                <p>Қуёш панеллари ташриф буюрувчилар сонини кўпайтиради ва муассаса обрўсини оширади</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
           
            <div className="home_div_3">
            <div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
                <h3>Завод ва фабрикалар</h3>
                <p>Давлат аллақачон зарядлаш станцияларини ўрнатиш тўғрисидаги қонунни<br /> ишлаб чиққан</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
             
              <div className="div_white_none"></div>
            </div>
            <div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up"> 
                <h3>Завод ва фабрикалар</h3>
                <p>Давлат аллақачон зарядлаш станцияларини ўрнатиш тўғрисидаги қонунни<br className='br_1'/> ишлаб чиққан</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
           
            <div className="home_div_4">
            <div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
                <h3>Уй ва тўхташ жойлар</h3>
                <p>Бу уйда фойдаланиш ва тўхташ жойларида қулай</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div> </div>
               
            </div>
            <div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up">
                <h3>Уй ва тўхташ жойлар</h3>
                <p>Бу уйда фойдаланиш ва тўхташ жойларида қулай</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
            
          </div>
        </div>

        <div className="plusSanner">
          <div className="plusSunner_1">
            <div className="plusSunner_card">
              <div className="plus_div"><h3>Нега бизни танлашади?</h3></div>
              <h3 className='plusSunner_card_h3'>Биз нафакат махсулотларни сотамиз, балки қуёш станцияларини ўрнатиш вазифангизни, хар томонлама хал қиламиз.</h3>
            </div>
            <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <FaAward />
                </div>
                <h3>25 Йил кафолат</h3>
                <p>Ва 1 йил бепул <span>техник хизмат</span></p>
              </div>
            </div>
            <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <PiCertificateFill />
                </div>
                <h3>OCPP сертификати</h3>
                <p>Намлик ва юқори ҳароратга <span>чидамли зарядлаш</span> станциялари</p>
              </div>
            </div>
          </div>
          <div className="plusSunner_1">
          <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <IoDocument />
                </div>
                <h3>Бир марталик тўлов</h3>
                <p>Биз сизнинг даромадингизнинг белгиланган <span>фоизини олмаймиз</span></p>
              </div>
            </div>
            <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <FaArrowUpWideShort />
                </div>
                <h3>Маҳсулотларнинг кенг ассортименти</h3>
                <p>Ҳар <span>қандай турдаги куёш станцияларини</span> ўрнатсангиз болади</p>
              </div>
            </div>
            <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <GiProgression />
                </div>
                <h3>Шерикчилик асосида ишлаш</h3>
                <p><span>Мижозлар билан</span> шерикчилик асосида ишлаш</p>
              </div>
            </div>
            <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <IoSettingsSharp />
                </div>
                <h3>Профессионаллар жамоаси</h3>
                <p>Юқори мураккаблик лойихалар <span>билан ишлаш</span> тажрибаси.</p>
              </div>
            </div>
          </div>
        </div>
  
        <div className="mission">
          <div className="mis_1">
          <div className="mission_div">
            <h2>Бизнинг вазифамиз</h2>
          </div>
          <div data-aos="fade-up-right">
          <h1>Биз дунёни тоза қиламиз, табиий ресурсларни тежаймиз ва одамларга қулайлик яратамиз</h1>
          </div>
          </div>
          <div className="mis_2">
          <div data-aos="fade-up-left"> <img src={IMG31} alt="" /></div>
          </div>
        </div>

        <div className="youtube">
          <h1>Батафсил малумотларни <span>YouTube</span> платформасидаги видеўларамиз оркали билиб олинг.</h1>
          <div className="youtube_div">     
          <iframe src="https://www.youtube.com/embed/sWbv1rY65i8?si=_y-ww_0tDAftxOUk?rel=0&showinfo=0&loop=1"></iframe>      
          <iframe src="https://www.youtube.com/embed/iaA9Sdd0Duk?si=zrCM2agmeaguz5-Z?rel=0&showinfo=0&loop=1"></iframe>
          <iframe src="https://www.youtube.com/embed/t4_kFLNY5MY?si=AFGiJknBNudVroVW?rel=0&showinfo=0&loop=1"></iframe>
          <iframe src="https://www.youtube.com/embed/zimfT5XLHKg?si=rd-9vf2hwp3q6pxk?rel=0&showinfo=0&loop=1"></iframe>
          </div>
        </div>

       <Form_input/>
        <Footer/>
    </div>  :  <div className='MAIN_div'>
      <div className="form_header_to_bot">
      <div className="form_help_div ">
      <MdClear onClick={() => {function_form_header_none()}} className="none_form_div_x" />
      <div className="zayavk_2 zayavk_p_div zayavk_2_cop_cop">
            <p>Fill in the following directory and we will contact you</p>
            <div className="zayavk_input">
              <input className='input_name' type="text" placeholder='Product name'/>
              <span className='position_span_form'>Enter information</span>
            </div>  
            <div className="zayavk_input">
              <input  className='input_phone' type="number" placeholder='99-999-9999'/>
              <span className='position_span_form'>Enter information</span>
            </div>
            <div className="zayavk_button">
              <button type='submit' onClick={() => {bot()}}>GET ADVICE</button>
              <div className='zayavk_ok_200'><span>WE CONNECT WITH YOU!</span></div>
            </div>
          </div>
      </div>
      </div>
      
      <Navbar />

      <div className="productFormDesc">
        <MdClear onClick={() => {noneFormProduct()}} className='icon_product_form'/>
        
        <div className="productIdForm">
          {element_1 == 0 ? 
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop_ENG[0].img} alt="" />
                <h3>{data_product_cop_cop_ENG[0].name_two}</h3>
                <p>{data_product_cop_cop_ENG[0].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop_ENG[0].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Full Name</h4>
                    <p>{data_product_cop_cop_ENG[0].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Profile</h4>
                    <p>{data_product_cop_cop_ENG[0].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Cable</h4>
                    <p>{data_product_cop_cop_ENG[0].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Production</h4>
                  <p>{data_product_cop_cop_ENG[0].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop_ENG[0].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                
              </div>
            </div>
        
          : element_1 == 1 ? 
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop_ENG[1].img} alt="" />
                <h3>{data_product_cop_cop_ENG[1].name_two}</h3>
                <p>{data_product_cop_cop_ENG[1].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop_ENG[1].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Full Name</h4>
                    <p>{data_product_cop_cop_ENG[1].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Profile</h4>
                    <p>{data_product_cop_cop_ENG[1].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Cable</h4>
                    <p>{data_product_cop_cop_ENG[1].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Production</h4>
                  <p>{data_product_cop_cop_ENG[1].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop_ENG[1].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                
              </div>
            </div>
        
          : element_1 == 2 ? 
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop_ENG[2].img} alt="" />
                <h3>{data_product_cop_cop_ENG[2].name_two}</h3>
                <p>{data_product_cop_cop_ENG[2].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop_ENG[2].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Full Name</h4>
                    <p>{data_product_cop_cop_ENG[2].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Profile</h4>
                    <p>{data_product_cop_cop_ENG[2].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Cable</h4>
                    <p>{data_product_cop_cop_ENG[2].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Production</h4>
                  <p>{data_product_cop_cop_ENG[2].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop_ENG[2].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                
              </div>
            </div>
         
          : element_1 == 3 ?
          
             <div className="divProductFormItem">
              <div className="divProductFormItem_img">
                <img src={data_product_cop_cop_ENG[3].img} alt="" />
                <h3>{data_product_cop_cop_ENG[3].name_two}</h3>
                <p>{data_product_cop_cop_ENG[3].title}</p>
              </div>
              <div className="divProductFormItem_map">
                <h3>{data_product_cop_cop_ENG[3].productInfo.fullProductName}</h3>
                <div className="divProductFormItem_map_div1">
                  <div className="div_info_minDiv">
                    <h4>Full Name</h4>
                    <p>{data_product_cop_cop_ENG[3].productInfo.fullProductType}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Profile</h4>
                    <p>{data_product_cop_cop_ENG[3].productInfo.fullProductType_2}</p>
                  </div>
                  <div className="div_info_minDiv">
                    <h4>Cable</h4>
                    <p>{data_product_cop_cop_ENG[3].productInfo.fullProductKabel}</p>
                  </div>
                </div>
                <div className="divProductFormItem_map_div2">
                  <h4>Production</h4>
                  <p>{data_product_cop_cop_ENG[3].productInfo.fullProductEnergy.energy_1}</p>
                  <p>{data_product_cop_cop_ENG[3].productInfo.fullProductEnergy.energy_2}</p>
                </div>
                
              </div>
            </div>
       
          : element_1 == 4 ?
          <div className="divProductFormItem">
          <div className="divProductFormItem_img">
            <img src={data_product_cop_cop_ENG[4].img} alt="" />
            <h3>{data_product_cop_cop_ENG[4].name_two}</h3>
            <p>{data_product_cop_cop_ENG[4].title}</p>
          </div>
          <div className="divProductFormItem_map">
            <h3>{data_product_cop_cop_ENG[4].productInfo.fullProductName}</h3>
            <div className="divProductFormItem_map_div1">
              <div className="div_info_minDiv">
                <h4>Full Name</h4>
                <p>{data_product_cop_cop_ENG[4].productInfo.fullProductType}</p>
              </div>
              <div className="div_info_minDiv">
                <h4>Profile</h4>
                <p>{data_product_cop_cop_ENG[4].productInfo.fullProductType_2}</p>
              </div>
              <div className="div_info_minDiv">
                <h4>Cable</h4>
                <p>{data_product_cop_cop_ENG[4].productInfo.fullProductKabel}</p>
              </div>
            </div>
            <div className="divProductFormItem_map_div2">
              <h4>Production</h4>
              <p>{data_product_cop_cop_ENG[4].productInfo.fullProductEnergy.energy_1}</p>
              <p>{data_product_cop_cop_ENG[4].productInfo.fullProductEnergy.energy_2}</p>
            </div>
            
          </div>
        </div>
        : element_1 == 5 ?
        <div className="divProductFormItem">
        <div className="divProductFormItem_img">
          <img src={data_product_cop_cop_ENG[5].img} alt="" />
          <h3>{data_product_cop_cop_ENG[5].name_two}</h3>
          <p>{data_product_cop_cop_ENG[5].title}</p>
        </div>
        <div className="divProductFormItem_map">
          <h3>{data_product_cop_cop_ENG[5].productInfo.fullProductName}</h3>
          <div className="divProductFormItem_map_div1">
            <div className="div_info_minDiv">
              <h4>Full Name</h4>
              <p>{data_product_cop_cop_ENG[5].productInfo.fullProductType}</p>
            </div>
            <div className="div_info_minDiv">
              <h4>Profile</h4>
              <p>{data_product_cop_cop_ENG[5].productInfo.fullProductType_2}</p>
            </div>
            <div className="div_info_minDiv">
              <h4>Cable</h4>
              <p>{data_product_cop_cop_ENG[5].productInfo.fullProductKabel}</p>
            </div>
          </div>
          <div className="divProductFormItem_map_div2">
            <h4>Production</h4>
            <p>{data_product_cop_cop_ENG[5].productInfo.fullProductEnergy.energy_1}</p>
            <p>{data_product_cop_cop_ENG[5].productInfo.fullProductEnergy.energy_2}</p>
          </div>
          
        </div>
      </div>
      : "" 
      }
        </div>
        </div>

      <header className='header_1'>
        <div className="div_header_absolute">
        </div>
        <div className="div_header_h1">
        <div data-aos="fade-right"> <h1>High-quality stations in Uzbekistan for the sale and installation of solar stations.</h1></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_header_span">
          <span>Practical renewable energy technology that reduces costs and helps the environment</span>
          </div></div>
          <div data-aos="fade-right">
          <div className='del_button'>
          <div className="div_header_button">
            <button onClick={() => {function_form_header()}}>To apply</button>
          </div>
          </div>
          </div>
      </header>

      <div className="card">
      <div data-aos="fade-up">
        <div className="card_1">
          <div className="card_div1">
          <IoSettingsSharp className='icon_card_glava'/>
            <div>
            <h2 className='ENG_translate_card_h2'>Installation complete</h2>
            </div>
          </div>
          <div className="card_div2">
            <p>We provide <span>full delivery</span>, installation and <span>maintenance</span> services.</p>
          </div>
        </div>
      </div>
      <div data-aos="fade-up">
        <div className="card_1">
          <div className="card_div1">
          <PiHandCoins className='icon_card_glava icon_card_glava_cop'/>
            <div>
            <h2>Installation service</h2>
            </div>
          </div>
          <div className="card_div2 card_div2_cop_1">
            <p>Installation and <span>delivery throughout</span> Uzbekistan</p>
          </div>
        </div>
        </div>
        <div data-aos="fade-up">
        <div className="card_1">
          <div className="card_div1">
          <PiCertificateFill className='icon_card_glava icon_card_glava_cop1'/>
            <div>
          
          <h2>Single offer in the Uzbek market</h2>
            </div>
          </div>
          <div className="card_div2 card_div2_cop">
            <p>There is a term <span>fee for solar stations.</span></p>
          </div>
        </div>
        </div>
      </div>
     
      <div className="glavaElectroStansiy">
        <div className="glavaElectro_teg"></div>
        <div className="electro_1">
          <h1>We have <span>already installed </span>a station from 700 to  throughout Uzbekistan</h1>
          </div>  
        <div className="big_electro">
            <div className="electro_2">
          <div data-aos="fade-right">
          <div className="electro_2_block">
              <div className="electro_2_block_div">
                <h2>More than 700</h2>
                <div className="icon_div"><IoIosStar /></div>
              </div>
              <p className='p_big1'>We installed to customers</p>
            </div>  
          </div>
        <div data-aos="fade-right">
            <div className="electro_2_block">
              <div className="electro_2_block_div">
                <h2>To 12 provinces</h2>
                <div className="icon_div"><IoIosStar /></div>
              </div>
              <p className='p_big'><span>Service to provinces</span ></p>
            </div>  
            </div>
        <div data-aos="fade-right">
        <div className="electro_2_block electro_2_block111">
              <div className="electro_2_block_div">
                <h2>Profisional community</h2>
                <div className="icon_div"><IoIosStar /></div>
              </div>
              <p className='p_big_ver'>Experience working with high-level <span>complex and large-scale loixes</span></p>
            </div>  
        </div>
          </div>  
          <div className="electro_3">
          <div data-aos="fade-left"><img className='electro_3_img_1' src={IMG5} alt="" /></div>
          </div> 
        </div> 
      </div>    

      <div className="brend">
        <h1>Brands<span> that have given</span> us confidence</h1>
        
        <div className="brend_1_div">
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG8} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"> <img src={IMG9} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG10} alt="" /></div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border"><img src={IMG11} alt="" />  </div>
          </div>
          <div data-aos="fade-right">
          <div className="div_img_border1"><img className='brend1_1_div_img' src={IMG13} alt="" /> </div>
          </div>
          <div className="div_img_border div_img_border_copiy2">
          <div data-aos="fade-right">
            <img src={IMG14} alt="" /></div>
          </div>
         
          </div>
              
          <div className="brend_1_div">
          <div className="div_img_border div_img_border_copiy"><div data-aos="fade-left"><img src={IMG14} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border "><img className='krg_foto' src={IMG15} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG12} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG16} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border"><img src={IMG17} alt="" /></div></div>
          <div data-aos="fade-left"><div className="div_img_border div_img_border_NEW_1"><img src={IMG_NEW_IMG1} alt="" /></div></div>
          </div>
        </div>

        <div className="yearElectro">
          <h1 className='year_h1'>In <span>Uzbekistan in 2023, 1.7 thousand</span> megawatt dowel stations will be installed.</h1>
          <p className='year_p'>Sunnur panels in Uzbekistan <span>market development</span></p>
          <div className="yearCard">
          <div data-aos="fade-right">
          <div className="yaerCard_1">
              <h1>$427.1 mln</h1>
              <p>Uzbekistan's solar panel imports increased by <span> 20 times</span> a total of $427.1 million <span> 3.85 million solar panels were imported from abroad in January-October.</span></p>
              
              <div className='card_teg_year'>
                <div className="yearCard_number">
                <span>1</span>
              </div>
              </div>

            </div>
          </div>
          <div data-aos="fade-down">
          <div className="yaerCard_1">
              <h1>3,791,363 units</h1>
              <p>21<span>countries </span> total<span>3 mln 791,363 </span> solar panels were brought in.</p>
              <div className='card_teg_year2'>
                <div className="yearCard_number">
                <span>2</span>
              </div>
              </div>
              
            </div>
          </div>
           
            <div data-aos="fade-left">
            <div className="yaerCard_1">
              <h1>1,700 thousand</h1>
              <p>In 2023, <span>1.7 thousand</span> megawatts of solar panels were <span>installed in the apartments</span> of entrepreneurs, social sphere and population.</p>
              <div className='card_teg_year3'>
              <div className="yearCard_number">
                <span>3</span>
              </div>
              </div>
            </div>
            </div>
            
          </div>
        </div>

        <div className="product">
          <h1>Our <span>products</span></h1>
          <div className="product_div">
            {data_product_cop_cop_ENG.map((item,key) => {
              return <div>
              <div data-aos="fade-up"><div className="product_div_1">
              <div className="product_img"><img src={item.img} alt="" /></div>
              <h3>{item.name}</h3>
              <p>{item.title}</p>
              <div onClick={() => {productFunctionForm_Cop(key)}} className="product_button"><button>MORE DETAILS</button></div>
            </div></div>
              </div>
            })}  
          </div>
        </div>

        <div className="popul">
          <h1>Sunnur <span>station brands</span></h1>
          <div className="popul_div">
            <div className="popul_div_1">
              <img className='img_px' src={IMG19} alt="" />
              <img className='img_px' src={IMG20} alt="" />
              <img src={IMG21} alt="" />
              <img className='img_logn_px' src={IMG22} alt="" />
              <img className='img_short_px' src={IMG23} alt="" />
              <img className='img_logn_px1' src={IMG24} alt="" />
            </div>
            <div className="popul_div_1">
            <img className='img_px' src={IMG25} alt="" />
            <img className='img_px' src={IMG26} alt="" />
            <img src={IMG29} alt="" />
            <img className='img_logn_px' src={IMG28} alt="" />
            <img className='img_px' src={IMG27} alt="" />
            <img className='img_px2 ' src={IMG30} alt="" />
            </div>
          </div>
        </div>

        <div className="home">
         
          <h1>Groom stations can be used for <span>business and users</span> extents.</h1>
          <div className="home_div">

            <div className="home_div_1">
            <div data-aos="fade-up"><div className="home_div_tegi home_div_tegi_3">
                <h3>Business centers</h3>
                <p>Employees can charge the required kurilma during the working day</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
              
            </div>
           <div className="home_div_tegi home_div_tegi_2"> <div data-aos="fade-up">
                <h3>Business centers</h3>
                <p>Employees can charge the required kurilma during the working day</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
            
            <div className="home_div_2">
            <div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
                <h3>Hotel and restaurant</h3>
                <p>Groom panels increase the number of visitors and increase the reputation of the institution</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
             
            </div>
            <div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up"> 
                <h3>Hotel and restaurant</h3>
                <p>Groom panels increase the number of visitors and increase the reputation of the institution</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
           
            <div className="home_div_3">
            <div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
                <h3>Factories and factories</h3>
                <p>The state has already passed the law on the installation of charging stations set</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
             
              <div className="div_white_none"></div>
            </div>
            <div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up"> 
                <h3>Factories and factories</h3>
                <p>The state has already passed the law on the installation of charging stations set</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
           
            <div className="home_div_4">
            <div data-aos="fade-up"> <div className="home_div_tegi home_div_tegi_3">
                <h3>Home and parking</h3>
                <p>It is convenient for home use and promotion of parking spaces</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div> </div>
               
            </div>
            <div className="home_div_tegi home_div_tegi_2"><div data-aos="fade-up">
                <h3>Home and parking</h3>
                <p>It is convenient for home use and promotion of parking spaces</p>
                <div className='home_icon'>
          <FaSolarPanel />
          </div>
              </div></div>
            
          </div>
        </div>

        <div className="plusSanner">
          <div className="plusSunner_1">
            <div className="plusSunner_card">
              <div className="plus_div"><h3>Why choose us?</h3></div>
              <h3 className='plusSunner_card_h3'>We not only sell excellent products, but also fulfill your installation task at solar stations.</h3>
            </div>
            <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <FaAward />
                </div>
                <h3>25 Years warranty</h3>
                <p>And 1 year <span>free maintenance</span></p>
              </div>
            </div>
            <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <PiCertificateFill />
                </div>
                <h3>OCPP certification</h3>
                <p>Charging stations resistant to <span>moisture and high temperatures</span></p>
              </div>
            </div>
          </div>
          <div className="plusSunner_1">
          <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <IoDocument />
                </div>
                <h3>One-time payment</h3>
                <p>We will not receive a <span>fixed percentage of your </span>income</p>
              </div>
            </div>
            <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <FaArrowUpWideShort />
                </div>
                <h3>Wide range of products</h3>
                <p>You can <span>install solar panels</span> of any type</p>
              </div>
            </div>
            <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <GiProgression />
                </div>
                <h3>Partnership-based performance</h3>
                <p><span>Working with clients</span> on a partnership basis</p>
              </div>
            </div>
            <div className="plusSunner_card_2">
              <div className="plusSunner_card_1">
                <div className="plusSunner_div">
                <IoSettingsSharp />
                </div>
                <h3>Team of professionals</h3>
                <p>Experience working <span>with high complexity sliders.</span></p>
              </div>
            </div>
          </div>
        </div>
  
        <div className="mission">
          <div className="mis_1">
          <div className="mission_div">
            <h2>Our mission</h2>
          </div>
          <div data-aos="fade-up-right">
          <h1>We make the world clean, save natural resources and make people comfortable</h1>
          </div>
          </div>
          <div className="mis_2">
          <div data-aos="fade-up-left"> <img src={IMG31} alt="" /></div>
          </div>
        </div>

        <div className="youtube">
          <h1>Find out more information <span>through our videos on the Youtube</span> platform</h1>
          <div className="youtube_div">           
          <iframe src="https://www.youtube.com/embed/sWbv1rY65i8?si=_y-ww_0tDAftxOUk?rel=0&showinfo=0&loop=1"></iframe>      
          <iframe src="https://www.youtube.com/embed/iaA9Sdd0Duk?si=zrCM2agmeaguz5-Z?rel=0&showinfo=0&loop=1"></iframe>
          <iframe src="https://www.youtube.com/embed/t4_kFLNY5MY?si=AFGiJknBNudVroVW?rel=0&showinfo=0&loop=1"></iframe>
          <iframe src="https://www.youtube.com/embed/zimfT5XLHKg?si=rd-9vf2hwp3q6pxk?rel=0&showinfo=0&loop=1"></iframe>
          </div>
        </div>

       <Form_input/>
        <Footer/>
    </div>}
   </div>
  )
}
