import React from 'react'
import { useState } from 'react';

import "../css/Navbar.css"

import IMG1 from "../img/photo_2024-02-06_14-12-42.jpg"

import { HiMenuAlt3 } from "react-icons/hi";
import { IoClose } from "react-icons/io5";

export default function Navbar() {
  const [las, setLas] = useState(localStorage.getItem("lan"))
    const [stateMenu, setStateMenu] = useState(1)

    function openMenu(){
      if((stateMenu === 1)){
        setStateMenu(2)
        document.querySelector(".navbar_menu").style = "right: -100px"
        document.querySelector(".navbar_menu_none").style = "right: 10px"
        document.querySelector(".menu").style = "top: 99px; z-index: 120; opacity: 1"
      window.onscroll = function () { window.scrollTo(10, 10); };
      }
    }
    function blockMenu(){
      if(stateMenu === 2){
        setStateMenu(1)
        document.querySelector(".navbar_menu").style = "right: 10px"
        document.querySelector(".navbar_menu_none").style = "right: -100px"
        document.querySelector(".menu").style = "z-index: -120; opacity: 0; top: -120vh;"
      window.onscroll = function () {};
      }
    }

    function language_UZ(){
      localStorage.setItem("lan",0)
      window.location.reload()
    }
    function language_EN(){
      localStorage.setItem("lan",1)
      window.location.reload()
    }
  return (
    <div>
      {las == 0 ? (<div> <div className='Navbar'>
    <div className="navbar">
        <div onClick={() => window.location = "/"} className="navbar_logo">
          <img src={IMG1} alt="" />
        </div>
        <div className="navbar_page">
          <span onClick={() => window.location = "/"} className='span_navbar1 span_navbar1_func'>Асосий</span>
          <span onClick={() => window.location = "/product"} className='span_navbar2 span_navbar1_func'>Махсулотлар</span>
          <span onClick={() => window.location = "/proect"} className='span_navbar3 span_navbar1_func'>Лойиҳалар</span>
          <span onClick={() => window.location = "/company"} className='span_navbar4 span_navbar1_func'>Компания ҳақида</span>
          <span onClick={() => window.location = "/contact"} className='span_navbar5 span_navbar1_func'>Алоқалар</span>
          <span onClick={() => window.location = "/bloc"} className='span_navbar6 span_navbar1_func'>Блог</span>
        </div>
        <div className="navbar_form">
          <div className="navbar_form_button"><a href='tel:+998978137700'>+998 97 813 77 00</a></div>
          <div onClick={() => {language_UZ()}} className='form_language'><span>УЗ</span></div>
          <div onClick={() => {language_EN()}} className='form_language'><span>ЕН</span></div>
        </div>
        <div onClick={() => {openMenu()}} className="navbar_menu">
        <HiMenuAlt3 />
        </div>
        <div onClick={() => {blockMenu()}} className="navbar_menu_none">
        <IoClose />
        </div>
        
      </div>

      <div className="menu">
        <div className="menu_div">
        <span onClick={() => window.location = "/"} className='span_menu1'>Асосий</span><br />
          <span onClick={() => window.location = "/product"} className='span_menu2'>Махсулотлар</span><br />
          <span onClick={() => window.location = "/proect"}  className='span_menu3'>Лойиҳалар</span><br />
          <span onClick={() => window.location = "/company"} className='span_menu4'>Компания ҳақида</span><br />
          <span onClick={() => window.location = "/contact"} className='span_menu5'>Алоқалар</span><br />
          <span onClick={() => window.location = "/bloc"} className='span_menu6'>Блог</span><br />
          <div className="menu_form_button"><a href='tel:+998978137700'>+998 97 813 77 00</a></div>
          <div className="menu_lan">
          <div onClick={() => {language_UZ()}} className='form_language'><span>УЗ</span></div>
          <div onClick={() => {language_EN()}} className='form_language'><span>ЕН</span></div>
          </div>
        </div>
      </div>

    </div></div>) : (<div> <div className='Navbar'>
    <div className="navbar">
        <div onClick={() => window.location = "/"} className="navbar_logo">
          <img src={IMG1} alt="" />
        </div>
        <div className="navbar_page">
          <span onClick={() => window.location = "/"} className='span_navbar1 span_navbar1_func'>Main</span>
          <span onClick={() => window.location = "/product"} className='span_navbar2 span_navbar1_func'>Products</span>
          <span onClick={() => window.location = "/proect"} className='span_navbar3 span_navbar1_func'>Projects</span>
          <span onClick={() => window.location = "/company"} className='span_navbar4 span_navbar1_func'>Company</span>
          <span onClick={() => window.location = "/contact"} className='span_navbar5 span_navbar1_func'>Contacts</span>
          <span onClick={() => window.location = "/bloc"} className='span_navbar6 span_navbar1_func'>Blog</span>
        </div>
        <div className="navbar_form">
          <div className="navbar_form_button"><a href='tel:+998978137700'>+998 97 813 77 00</a></div>
          <div onClick={() => {language_UZ()}} className='form_language'><span>UZ</span></div>
          <div onClick={() => {language_EN()}} className='form_language'><span>EN</span></div>
        </div>
        <div onClick={() => {openMenu()}} className="navbar_menu">
        <HiMenuAlt3 />
        </div>
        <div onClick={() => {blockMenu()}} className="navbar_menu_none">
        <IoClose />
        </div>
        
      </div>

      <div className="menu">
        <div className="menu_div">
        <span onClick={() => window.location = "/"} className='span_menu1'>Main</span><br />
          <span onClick={() => window.location = "/product"} className='span_menu2'>Products</span><br />
          <span onClick={() => window.location = "/proect"}  className='span_menu3'>Projects</span><br />
          <span onClick={() => window.location = "/company"} className='span_menu4'>Company</span><br />
          <span onClick={() => window.location = "/contact"} className='span_menu5'>Contacts</span><br />
          <span onClick={() => window.location = "/bloc"} className='span_menu6'>Blog</span><br />
          <div className="menu_form_button"><a href='tel:+998978137700'>+998 97 813 77 00</a></div>
          <div className="menu_lan">
          <div onClick={() => {language_UZ()}} className='form_language'><span>UZ</span></div>
          <div onClick={() => {language_EN()}} className='form_language'><span>EN</span></div>
          </div>
        </div>
      </div>

    </div></div>)}
    </div>
   
  )
}


